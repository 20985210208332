export default [
    {
        path: 'professor',
        name: 'ProfessorHome',
        meta: {title: "Início"},
        component: () => import('@pages/ProfessorPages/Home')
    },
    {
        path: 'professor/cadastro',
        name: 'ProfessorInfo',
        meta: {title: "Cadastro"},
        component: () => import('@pages/ProfessorPages/Info')
    },
    {
        path: 'professor/nota/aluno/:id',
        props: true,
        name: 'NotaAlunoProfessor',
        meta: { title: "Lançamento de Nota por Aluno" },
        component: () => import('@pages/Nota/NotaAluno')
    },
    {
        path: 'professor/nota/aluno/create/:matricula_id/:turma_id',
        props: true,
        name: 'NotaAlunoCreateProfessor',
        meta: { title: "Lançamento de Notas por Aluno" },
        component: () => import('@pages/Nota/NotaAlunoCreate')
    },
    {
        path: 'professor/nota/disciplina/:id',
        props: true,
        name: 'NotaDisciplinaProfessor',
        meta: { title: "Lançamento de Notas por Disciplina"},
        component: () => import('@pages/Nota/NotaDisciplina')
    },
    {
        path: 'professor/nota/disciplina/create/:turma_id/:disciplina_id',
        props: true,
        name: 'NotaDisciplinaCreateProfessor',
        meta: { title: "Lançamento de Notas por Disciplina" },
        component: () => import('@pages/Nota/NotaDisciplinaCreate')
    },
    {
        path: 'professor/turmas',
        name: 'ProfessorTurmas',
        meta: {title: "Turmas"},
        component: () => import('@pages/ProfessorPages/ProfessorTurmas')
    },
    {
        path: 'professor/registros/:turma_id',
        props: true,
        name: 'RegistroTurma',
        meta: {title: "Turmas"},
        component: () => import('@pages/ProfessorPages/RegistroTurma')
    },
    {
        path: 'professor/adicionar-registro/:turma_id',
        props: true,
        name: 'AdicionarRegistroProfessor',
        meta: {title: "Adicionar Registro"},
        component: () => import('@pages/ProfessorPages/AdicionarRegistroProfessor')
    },
    {
        path: 'professor/editar-registro/:registro_aula_id',
        props: true,
        name: 'EditarRegistroProfessor',
        meta: {title: "Editar Registro"},
        component: () => import('@pages/ProfessorPages/EditarRegistroProfessor')
    },
    {
        path: 'adicionar-justificativa/:registro_aula_id',
        props: true,
        name: 'AdicionarJustificativa',
        meta: {title: "Adicionar Justificativa"},
        component: () => import('@pages/RegistroAula/AdicionarJustificativa')
    },
    {
        path: 'professor/turmas/boletim/:id',
        props: true,
        name: 'BoletimTurma',
        meta: {title: "Turmas"},
        component: () => import('@pages/ProfessorPages/BoletimTurma')
    },
    {
        path: 'professor/calendario',
        name: 'ProfessorCalendario',
        meta: {title: "Calendário"},
        component: () => import('@pages/ProfessorPages/Calendario')
    },
    {
        path: 'professor/diario',
        name: 'ProfessorDiario',
        meta: {title: "Diário de Classe"},
        component: () => import('@pages/ProfessorPages/ProfessorDiario')
    },
    {
        path: 'professor/agendaescolar',
        name: 'ProfessorAgenda',
        meta: {title: "Agenda Escolar"},
        component: () => import('@pages/ProfessorPages/ProfessorAgenda')
    },
    {
        path: 'professor/agendaescolar/create',
        name: 'ProfessorAgendaCreate',
        meta: {title: "Agenda Escolar"},
        component: () => import('@pages/ProfessorPages/ProfessorAgendaCreate')
    },
    {
        path: 'professor/agendaescolar/show/:id',
        props: true,
        name: 'ProfessorAgendaShow',
        meta: {title: "Visualizar Agenda Escolar"},
        component: () => import('@pages/ProfessorPages/ProfessorAgendaShow')
    },
    {
        path: 'professor/agendaescolar/edit/:id',
        props: true,
        name: 'ProfessorAgendaEdit',
        meta: {title: "Editar Agenda Escolar"},
        component: () => import('@pages/ProfessorPages/ProfessorAgendaEdit')
    },
    {
        path: 'portal/professor/agendaescolar/midia/:agenda_escolar_id',
        props: true,
        name: 'ProfessorAgendaMidia',
        meta: {title: "Mídia de Agenda Escolar"},
        component: () => import('@pages/ProfessorPages/ProfessorAgendaMidia')
    },
    {
        path: 'professor/cadastro/:id',
        props: true,
        name: 'ProfessorCadastro',
        meta: { title: "Meu Cadastro" , permissions: ['professor_editar']},
        component: () => import('@pages/ProfessorPages/ProfessorCadastro')
    },
    {
        path: 'professor/senha/:id',
        props: true,
        name: 'ProfessorSenhaEdit',
        meta: { title: "Alterar Senha", permissions: ['professor_editar'] },
        component: () => import('@pages/ProfessorPages/ProfessorSenhaEdit')
    },
    {
        path: 'professor/comunicado',
        name: 'ProfessorComunicado',
        meta: {title: "Comunicados"},
        component: () => import('@pages/ProfessorPages/Comunicado')
    },
    {
        path: 'professor/recebimento-material',
        name: 'ProfessorRecebimentoMaterial',
        meta: {title: "RecebimentoMaterial"},
        component: () => import('@pages/ProfessorPages/ProfessorRecebimentoMaterial')
    },
]
