export default [
    {
        path: 'relatorio/matricula',
        name: 'RelatorioMatricula',
        meta: {title: "Relatório de Matrícula"},
        component: () => import('@pages/Relatorio/Matricula')
    },
    {
        path: 'relatorio/alunos',
        name: 'RelatorioAluno',
        meta: {title: "Relatório de Alunos"},
        component: () => import('@pages/Relatorio/Aluno')
    },
    {
        path: 'relatorio/professor',
        name: 'RelatorioProfessor',
        meta: {title: "Relatório de Professor"},
        component: () => import('@pages/Relatorio/Professor')
    },
    {
        path: 'relatorio/funcionario',
        name: 'RelatorioFuncionario',
        meta: {title: "Relatório de Funcionario"},
        component: () => import('@pages/Relatorio/Funcionario')
    },
    {
        path: 'relatorio/cliente',
        name: 'RelatorioCliente',
        meta: {title: "Relatório de Cliente"},
        component: () => import('@pages/Relatorio/Cliente')
    },
    {
        path: 'relatorio/fornecedor',
        name: 'RelatorioFornecedor',
        meta: {title: "Relatório de Fornecedor"},
        component: () => import('@pages/Relatorio/Fornecedor')
    },
    {
        path: 'relatorio/material',
        name: 'RelatorioMaterial',
        meta: {title: "Relatório de Material"},
        component: () => import('@pages/Relatorio/Material')
    },
    {
        path: 'relatorio/servico',
        name: 'RelatorioServico',
        meta: {title: "Relatório de Serviço"},
        component: () => import('@pages/Relatorio/Servico')
    },
    {
        path: 'relatorio/venda',
        name: 'RelatorioVenda',
        meta: {title: "Relatório de Venda"},
        component: () => import('@pages/Relatorio/Venda')
    },
    {
        path: 'relatorio/registroaula',
        name: 'RelatorioRegistroAula',
        meta: {title: "Relatório de Registro de Aula"},
        component: () => import('@pages/Relatorio/RegistroAula')
    },
    {
        path: 'relatorio/frequencia',
        name: 'RelatorioFrequencia',
        meta: {title: "Relatório de Frequência"},
        component: () => import('@pages/Relatorio/Frequencia')
    },
    {
        path: 'relatorio/conta-corrente',
        name: 'RelatorioContaCorrente',
        meta: {title: "Relatório de Conta Corrente"},
        component: () => import('@pages/Relatorio/ContaCorrente')
    },
    {
        path: 'relatorio/lancamento',
        name: 'RelatorioLancamento',
        meta: {title: "Relatório de Lançamento"},
        component: () => import('@pages/Relatorio/Lancamento')
    },
    {
        path: 'relatorio/financeiro-aluno',
        name: 'RelatorioFinanceiroAluno',
        meta: {title: "Relatório Financeiro do Aluno"},
        component: () => import('@pages/Relatorio/FinanceiroAluno')
    },
    {
        path: 'relatorio/boleto-bancario',
        name: 'RelatorioBoletoBancario',
        meta: {title: "Relatório de Boleto Bancário"},
        component: () => import('@pages/Relatorio/BoletoBancario')
    },
    {
        path: 'relatorio/personalizado',
        name: 'RelatorioPersonalizado',
        meta: {title: "Relatório Personalizado"},
        component: () => import('@pages/Relatorio/Personalizado')
    },
    
]
