import api from "./api";

export default {
    async get(){
        return api.get(`/personalizacao`)
    },
    async update(form){
        return api.post(`/personalizacao`, form)
    },
}
