<template>
    <div>
        <!-- <v-dialog v-model="loading" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Carregando informações
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog> -->
        <ErrorModal ref="errorModal" />
        <router-view></router-view>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ErrorModal from "@components/ErrorModal"
import personalizacaoApi from "@api/personalizacao"

export default {
    components: {
        ErrorModal
    },
    computed: {
        ...mapGetters(["getLoading", "getErrorMsg"]),
        loading: {
            get() {
                return this.getLoading;
            },
            set(newValue) {
                this.$store.commit('setLoading', newValue);
            }
        },
        error: {
            get() {
                return this.getErrorMsg;
            },
            set(newValue) {
                this.$store.commit('setErrorMsg', newValue);
            }
        }
    },
    watch: {
        loading(val) {
        },
        error(val) {
            if (val) {
                this.$refs['errorModal'].show(val)
            } else {
                this.$refs['errorModal'].dialog = false;
            }
        },
    },
    beforeMount() {
        personalizacaoApi.get().then(result => {
            this.$store.state.cor = result.data.cor
            this.$store.state.logo = result.data.imagem
        })
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

* {
    --azul-claro: #0BACEA;
    --azul: #563EED;
    --branco: #fdfdfd;
    --principal: #1f561a;
    --vermelho: #EE7B74;
    --rosa: #fc75ff;
    --verde: #11C182;
    --verde-escuro: #3d6169;
    --verde-folha: #0c6b4a;
    --btn-color: #854CF3;
    --amarelo: #e3a24d;
    --verdinho: #427a60;
}

a {
    text-decoration: none;
}

.icon {
    border-radius: 5px;
    color: var(--branco) !important;
    height: 24px;
    width: 24px;
    font-size: 16px !important;
}

.icon-small {
    border-radius: 5px;
    color: var(--branco) !important;
    height: 19px;
    width: 19px;
    font-size: 13px !important;
}

.icon-mini {
    border-radius: 5px;
    color: var(--branco) !important;
    height: 17px;
    width: 17px;
    font-size: 10px !important;
    transform: translateY(-10px)
}


.icone-foto {
    border-radius: 50% !important;
    color: var(--branco) !important;
    height: 24px !important;
    width: 24px !important;
    font-size: 16px !important;

}

.principal {
    color: var(--azul);
}

.secundario {
    background-color: var(--btn-color);
}

.show {
    background-color: var(--azul-claro);
}

.edit {
    background-color: var(--azul);
}

.delete {
    background-color: var(--vermelho);
}

.add {
    background-color: var(--verde);
}

.detail {
    background-color: var(--verde-escuro);
}

.print {
    background-color: #811ecf;
}

.link {
    background-color: var(--verdinho);
}

.up {
    background-color: var(--azul-claro);
}

.down {
    background-color: var(--rosa);
}

.cancel {
    background-color: rgb(252, 50, 50);
}


.copy {
    background-color: var(--amarelo);
}

.desabilitado {
    background-color: lightgray;
}

.v-input__icon--clear button {
    font-size: 14px !important;
    transform: translateX(13px);
}

.theme--light.v-subheader {
    color: black;
    font-size: 16px;
    font-weight: bold;
}

.cadastro-select {
    color: var(--azul);
    font-weight: 600;
}

.cadastro-select-icon {
    border-radius: 50%;
    color: var(--branco) !important;
    height: 24px;
    width: 24px;
    font-size: 16px !important;
    background-color: var(--azul);
}

.cadastro-item {
    position: sticky;
    bottom: 0;
    background-color: white;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.title-card{
    color: white !important;
}

/* ---------------------------- RELATORIO ---------------------------------- */
.relatorio-page {
    padding: 24px;
    width: 100%;
    margin: 0;
}

.relatorio-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.relatorio-logo {
    max-width: 170px;
    max-height: 75px;
}

.relatorio-titulo {
    text-align: center;
}

.relatorio-unidade {
    text-align: end;
    font-size: 12px;
    padding-bottom: 0
}

.relatorio-divider {
    border: 1px solid #898989 !important;
    margin: 10px 0;
}

.relatorio-filtros {
    font-size: 12px;
    align-items: flex-start;

}

.relatorio-filtros .filtro{
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.relatorio-filtros b {
    font-weight: bold;
}


.relatorio-tabela th,
.relatorio-tabela td {
    color: #616161 !important;
    text-align: start;
    height: 16px !important;
    border-bottom: solid rgba(0, 0, 0, .12) 2px !important;
    font-size: 12px !important;
}

.relatorio-tabela.impressao th,
.relatorio-tabela.impressao td {
    color: black !important;
}

.relatorio-tabela th {
    font-size: 11px !important;
}

.relatorio-tabela,
td.info-linha {
    font-size: 11px !important;
}

.relatorio-tabela,
td.info-linha.menor {
    font-size: 10px !important;
}

.relatorio-tabela .total {
    font-weight: bold;
}

.relatorio-tabela.entidades td,
.relatorio-tabela.entidades th {
    border-bottom: none !important;
}

.relatorio-tabela.entidades .nome-aluno,
.relatorio-tabela.entidades .total {
    border-top: solid rgba(0, 0, 0, .12) 2px !important;

}

.relatorio-tabela .nome-aluno,
.relatorio-tabela .nome-entidade {
    text-transform: uppercase;
}

.relatorio-tabela .nome-aluno-material {
    font-size: 14px !important;
    font-weight: bold;
    color: black !important;

}

.relatorio-tabela.material .nome-aluno-material {
    height: 40px !important;
}


.relatorio-tabela.material td.info-linha {
    border-top: 1px solid lightgray !important;
}

.relatorio-tabela.material td.info-linha.total{
    border-top: none !important;
}

.relatorio-tabela.material td,
.relatorio-tabela.material td .total {
    border-bottom: none !important;
}

.relatorio-tabela.servico th, .relatorio-tabela.servico td{
    border: none !important;
}

.relatorio-tabela.servico th{
    color: darkblue !important;
}


.btn-align-print {
    display: flex;
    justify-content: flex-end;
}

.relatorio-tabela-legenda th,
.relatorio-tabela-legenda td {
    color: #616161 !important;
    text-align: start;
    height: 16px !important;
    border-bottom: solid rgba(0, 0, 0, .12) 1px !important;
    font-size: 10px !important;
}

.relatorio-tabela-legenda th,
.relatorio-tabela-legenda td {
    border-left: solid rgba(0, 0, 0, .12) 1px !important;
}

.relatorio-tabela-legenda, .relatorio-tabela-frequencia{
    border-right: solid rgba(0, 0, 0, .12) 1px !important;
    border-top: solid rgba(0, 0, 0, .12) 1px !important;
}

.relatorio-tabela-frequencia th,
.relatorio-tabela-frequencia td {
    color: #616161 !important;
    text-align: start;
    height: 16px !important;
    border-bottom: solid rgba(0, 0, 0, .12) 1px !important;
    border-left: solid rgba(0, 0, 0, .12) 1px !important;
    font-size: 10px !important;
}

.relatorio-tabela-frequencia th{
    text-align: center;
    font-size: 11px !important;
}


@media print {
    .relatorio-logo {
        max-width: 120px;
        max-height: 50px;
    }

    .relatorio-unidade,
    .relatorio-filtros {
        font-size: 9px;
    }

    .relatorio-tabela th,
    .relatorio-tabela td {
        height: 14px !important;
        font-size: 10px !important;
    }


    .relatorio-tabela,
    td.info-linha {
        font-size: 8px !important;
    }


    .noprint {
        display: none;
    }



    .relatorio-filtros .filtro {
        min-width: 200px;
        max-width: 200px;
        padding-top: 2px !important;
        padding-bottom: 2px !important;
    }

    .relatorio-divider {
        margin: 15px 0;
    }

    .relatorio-filtros {
        font-size: 10px;
    }
}
</style>
