import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store'

import cadastrosRoutes from './cadastros'
import administrativoRoutes from './administrativo'
import academicoRoutes from './academico'
import financeiroRoutes from './financeiro'
import professorRoutes from './professor'
import impressaoRoutes from './impressao'
import siteRoutes from './site'
import relatorioRoutes from './relatorio'
import alunoRoutes from './aluno'
import comunicacaoRoutes from './comunicacao'
import configuracoesRoutes from './configuracoes'

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        meta: { requiresAuth: true },
        component: () => import('@templates/default/Index'),
        children: [
            ...cadastrosRoutes,
            ...administrativoRoutes,
            ...comunicacaoRoutes,
            ...financeiroRoutes,
            ...academicoRoutes,
            ...relatorioRoutes,
            ...configuracoesRoutes,
            
        ]
    },
    {
        path: "/site/",
        component: () => import('@templates/site/Index'),
        children: [
            ...siteRoutes,
        ]
    },
    {
        path: "/impressao",
        component: () => import('@templates/default/Impressao'),
        children: [
            ...impressaoRoutes,
        ]
    },
    {
        path: "/portal",
        component: () => import('@templates/default/IndexPortal'),
        children: [
            ...alunoRoutes,
            ...professorRoutes,
        ]
    },
    {
        path: "/login",
        name: "Login",
        component: () => import('@pages/Auth/Login'),
        //meta: {guest: true},
    },
    {
        path: "/boleto/:parcela_id/:convenio_id",
        props: true,
        name: "BoletoPrint",
        component: () => import('@pages/Impressao/BoletoPrint'),
        //meta: {guest: true},
    },
    {
        path: "/carne/:lancamento_id/:convenio_id",
        props: true,
        name: "CarnePrint",
        component: () => import('@pages/Impressao/CarnePrint'),
        //meta: {guest: true},
    },
    {
        path: '/nova-matricula',
        name: 'NovaMatricula',
        meta: { title: "Nova Matrícula" },
        component: () => import('@pages/NovaMatrícula/NovaMatricula')
    },
    {
        path: '/nova-matricula/responsavel',
        props: true,
        name: 'NovaMatriculaResponsavel',
        meta: { title: "Nova Matrícula - Responsável" },
        component: () => import('@pages/NovaMatrícula/NovaMatriculaResponsavel')
    },
    {
        path: '/nova-matricula/aluno/:responsavel_id',
        props: true,
        name: 'NovaMatriculaAluno',
        meta: { title: "Nova Matrícula - Aluno" },
        component: () => import('@pages/NovaMatrícula/NovaMatriculaAluno')
    },
    {
        path: '/nova-matricula/aluno-cadastrado/:responsavel_id',
        props: true,
        name: 'AlunoCadastrado',
        meta: { title: "Alunos Cadastrados" },
        component: () => import('@pages/NovaMatrícula/AlunoCadastrado')
    },
    {
        path: '/nova-matricula/curso-turma/:aluno_id',
        props: true,
        name: 'CursoTurma',
        meta: { title: "Matrícula - Curso e Turma" },
        component: () => import('@pages/NovaMatrícula/CursoTurma')
    },
    /*   {
          path: '/configuracao-inicial',
          name: 'ConfiguracaoInicial',
          meta: {title: "Configurações Iniciais"},
          component: () => import('@pages/ConfiguracaoInicial/ConfiguracaoInicial')
      }, */
    {
        path: '/nova-matricula/info/:matricula_id',
        props: true,
        name: 'InformacaoDocumento',
        meta: {title: "Matrícula - Informações e Documentos"},
        component: () => import('@pages/NovaMatrícula/InformacaoDocumento')
    },
    {
        path: "/recuperar-senha",
        name: "RecuperarSenha",
        meta: { title: "Recuperar Senha" },
        component: () => import('@pages/Auth/RecuperarSenha'),
    },
    {
        path: "/inicio",
        name: "InicioDadoUnidade",
        meta: { title: "Início - Dados da Unidade" },
        component: () => import('@pages/Inicio/DadoUnidade'),
    },
    {
        path: "/inicio/modalidades",
        name: "InicioModalidadeOfertada",
        meta: { title: "Início - Modalidades Ofertadas" },
        component: () => import('@pages/Inicio/ModalidadeOfertada'),
    },
    {
        path: "/inicio/financeiro",
        name: "InicioFinanceiro",
        meta: { title: "Início - Financeiro" },
        component: () => import('@pages/Inicio/Financeiro'),
    },
    {
        path: "/inicio/personalizacao",
        name: "InicioPersonalizacao",
        meta: { title: "Início - Personalização" },
        component: () => import('@pages/Inicio/Personalizacao'),
    },
]


const router = new VueRouter({
    mode: "history",
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 };
    }
})

function loggedIn() {
    return store.state.isLogged;
}


const DEFAULT_TITLE = 'Minha Escola';
router.afterEach((to, from) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
})

router.beforeEach((to, from, next) => {

    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!loggedIn()) {
            next({
                path: '/login',
                query: { redirect: to.fullPath }
            })
        }
        else {
            next()
        }
    } else if (to.matched.some(record => record.meta.guest)) {
        if (loggedIn()) {
            next({
                path: '/',
                query: { redirect: to.fullPath }
            })
        }
        else {
            next()
        }
    } else {
        next() // make sure to always call next()!
    }
})

export default router
