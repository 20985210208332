export default [
    {
        path: 'configuracao/perfil-acesso',
        name: 'PerfilAcesso',
        meta: { title: "Perfil de Acesso" },
        component: () => import('@pages/PerfilAcesso/PerfilAcessoList')
    },
    {
        path: 'configuracao/perfil-acesso/create',
        props: true,
        name: 'PerfilAcessoCreate',
        meta: { title: "Cadastro de Perfil de Acesso" },
        component: () => import('@pages/PerfilAcesso/PerfilAcessoCreate')
    },
    {
        path: 'configuracao/perfil-acesso/edit/:id',
        props: true,
        name: 'PerfilAcessoEdit',
        meta: { title: "Editar Perfil de Acesso" },
        component: () => import('@pages/PerfilAcesso/PerfilAcessoEdit')
    },
    {
        path: 'configuracao/perfil-acesso/permissoes/:cargo_id',
        props: true,
        name: 'PerfilAcessoPermissoes',
        meta: { title: "Permissões de Perfil de Acesso" },
        component: () => import('@pages/PerfilAcesso/PerfilAcessoPermissoes')
    },
    {
        path: 'configuracao/personalizacao',
        name: 'Personalizacao',
        meta: { title: "Personalização" },
        component: () => import('@pages/Configuracao/Personalizacao')
    },
    {
        path: 'configuracao/log-usuario',
        name: 'LogUsuario',
        meta: { title: "Log de Usuário" },
        component: () => import('@pages/Configuracao/LogUsuario')
    },
    {
        path: 'configuracao/geral',
        name: 'ConfiguracaoGeral',
        meta: { title: "Configurações" },
        component: () => import('@pages/Configuracao/ConfiguracaoGeral')
    },
    {
        path: 'configuracao/email',
        name: 'ConfigEmailList',
        meta: { title: "Configuração de Email" },
        component: () => import('@pages/ConfiguracaoEmail/ConfigEmailList')
    },
    {
        path: 'configuracao/email/create',
        name: 'ConfigEmailCreate',
        meta: { title: "Cadastro de Email" },
        component: () => import('@pages/ConfiguracaoEmail/ConfigEmailCreate')
    },
    {
        path: 'configuracao/email/edit/:id',
        props: true,
        name: 'ConfigEmailEdit',
        meta: { title: "Edição de Email" },
        component: () => import('@pages/ConfiguracaoEmail/ConfigEmailEdit')
    },
]
