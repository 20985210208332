<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title class="text-h5 red title-card">Alerta</v-card-title>
      <v-card-text class="my-5">
        <li v-for="error in errors" :key="error.id">
          <ul class="px-0">
            <li v-for="msg in error" :key="msg.id">{{ msg }}</li>
          </ul>
        </li>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" dark @click="dismiss">Ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      errors: [],
    };
  },
  methods: {
    show(errorList) {
      this.dialog = true;
      this.errors = errorList;
    },
    dismiss() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped>

ul,
li {
  list-style: none;
}

.title-card {
  color: var(--branco);
}
</style>
