export default [
    {
        path: 'comprovanteRematricula/:aluno_id/:matricula_id',
        props: true,
        name: 'ComprovanteRematricula',
        component: () => import('@pages/Impressao/ComprovanteRematricula')
    },
    {
        path: 'listamateriais/print/:id',
        props: true,
        name: 'ListaMaterialPrint',
        component: () => import('@pages/Impressao/ListaMaterialPrint')
    },
    {
        path: 'lista-livro/print/:id',
        props: true,
        name: 'ListaLivroPrint',
        component: () => import('@pages/Impressao/ListaLivroPrint')
    },
    {
        path: 'matricula/boletim/:id/:periodo?',
        props: true,
        name: 'BoletimPrint',
        component: () => import('@pages/Impressao/BoletimPrint')
    },

    {
        path: 'turma/diario-classe/:id',
        props: true,
        name: 'TurmaDiario',
        meta: { title: "Diário de Turma" },
        component: () => import('@pages/Turma/TurmaDiario')
    },
    {
        path: 'matricula/diario-classe/:id',
        props: true,
        name: 'MatriculaDiario',
        meta: { title: "Diário de Matrícula" },
        component: () => import('@pages/Matricula/MatriculaDiario')
    },
    {
        path: 'comprovanteNovaMatricula/:matricula_id/',
        props: true,
        name: 'ComprovanteNovaMatricula',
        component: () => import('@pages/Impressao/ComprovanteNovaMatricula')
    },
    {
        path: 'ficha-matricula/:matricula_id/',
        props: true,
        name: 'FichaMatricula',
        component: () => import('@pages/Impressao/FichaMatricula')
    },
    {
        path: 'carteira-estudante/:matricula_id/',
        props: true,
        name: 'CarteiraEstudante',
        component: () => import('@pages/Impressao/CarteiraEstudante')
    },


    {
        path: 'matricula/declaracao-quitacao/:matricula_id',
        props: true,
        name: 'DeclaracaoQuitacaoPrint',
        component: () => import('@pages/Impressao/DeclaracaoQuitacao')
    },
    {
        path: 'matricula/lista-material-livro/:matricula_id',
        props: true,
        name: 'ListaLivroMaterialPrint',
        component: () => import('@pages/Impressao/LivroMaterialPrint')
    },
    {
        path: 'aluno/ocorrencia/:id',
        props: true,
        name: 'OcorrenciaAlunoPrint',
        component: () => import('@pages/Impressao/OcorrenciaAlunoPrint')
    },
    {
        path: 'aluno/lista-ocorrencia/:id',
        props: true,
        name: 'ListaOcorrenciaAlunoPrint',
        component: () => import('@pages/Impressao/ListaOcorrenciaAlunoPrint')
    },
    {
        path: 'turma/boletim/:id',
        props: true,
        name: 'TurmaBoletim',
        meta: { title: "Boletim de Turma" },
        component: () => import('@pages/Turma/TurmaBoletim')
    },
    {
        path: 'turma/boletim/paginas/:id',
        props: true,
        name: 'TurmaBoletimPaginas',
        meta: { title: "Boletim de Turma" },
        component: () => import('@pages/Turma/TurmaBoletimPaginas')
    },
    {
        path: 'venda/fatura/:id',
        props: true,
        name: 'FaturaVenda',
        component: () => import('@pages/Impressao/FaturaVenda')
    },
    {
        path: 'compra/fatura/:id',
        props: true,
        name: 'FaturaCompra',
        component: () => import('@pages/Impressao/FaturaCompra')
    },
    {
        path: 'turma/horario-aula/:id',
        props: true,
        name: 'TurmaHorarioPrint',
        meta: { title: "Horário de Aula" },
        component: () => import('@pages/Turma/HorarioAulaPrint')
    },
    {
        path: 'turma/diario-classe-disciplina/:id/:disciplina_id',
        props: true,
        name: 'TurmaDiarioDisciplina',
        meta: { title: "Diário de Turma" },
        component: () => import('@pages/Turma/TurmaDiarioDisciplina')
    },
    {
        path: 'conta/comprovante-pagamento/:id',
        props: true,
        name: 'ComprovantePagamento',
        meta: { title: "Comprovante de Pagamento" },
        component: () => import('@pages/Impressao/ComprovantePagamento')
    },
    {
        path: 'aluno/historico/:aluno_id',
        props: true,
        name: 'HistoricoEscolarPrint',
        meta: { title: "Histórico Escolar" },
        component: () => import('@pages/Impressao/HistoricoEscolar')
    },
    {
        path: 'matricula/carne-escolar/:matricula_id',
        props: true,
        name: 'CarneEscolarPrint',
        meta: { title: "Carnê Escolar" },
        component: () => import('@pages/Impressao/Carne')
    },
    // Relatórios ------------------------------------------------------------------
    {
        path: 'relatorio/lista-aluno',
        name: 'RelatorioListaAlunoPrint',
        meta: { title: "Imprimir Relatório de Alunos" },
        component: () => import('@pages/Impressao/Relatorio/Cadastro/Aluno/ListaAluno')
    },

    {
        path: 'relatorio/aluno-completo',
        name: 'RelatorioAlunoCompletoPrint',
        meta: { title: "Imprimir Relatório de Alunos Completo" },
        component: () => import('@pages/Impressao/Relatorio/Cadastro/Aluno/AlunoCompleto')
    },

    {
        path: 'relatorio/aluno/matricula-responsavel',
        name: 'RelatorioAlunoMatriculaResponsavelPrint',
        meta: { title: "Imprimir Relatório de Alunos - Matrículas por Responsável" },
        component: () => import('@pages/Impressao/Relatorio/Cadastro/Aluno/MatriculaResponsavel')
    },

    {
        path: 'relatorio/aluno/responsavel',
        name: 'RelatorioAlunoResponsavelPrint',
        meta: { title: "Imprimir Relatório de Alunos - Responsáveis por Aluno" },
        component: () => import('@pages/Impressao/Relatorio/Cadastro/Aluno/Responsavel')
    },
    {
        path: 'relatorio/aluno/aplicativo',
        name: 'RelatorioAlunoAplicativoPrint',
        meta: { title: "Imprimir Relatório de Alunos - Uso de Aplicativo" },
        component: () => import('@pages/Impressao/Relatorio/Cadastro/Aluno/Aplicativo')
    },

    {
        path: 'relatorio/aluno/documento',
        name: 'RelatorioAlunoDocumentoPrint',
        meta: { title: "Imprimir Relatório de Alunos - Documento" },
        component: () => import('@pages/Impressao/Relatorio/Cadastro/Aluno/Documento')
    },

    {
        path: 'relatorio/aluno/telefone',
        name: 'RelatorioAlunoTelefonePrint',
        meta: { title: "Imprimir Relatório de Alunos - Telefone" },
        component: () => import('@pages/Impressao/Relatorio/Cadastro/Aluno/Telefone')
    },

    {
        path: 'relatorio/aluno/contato',
        name: 'RelatorioAlunoContatoPrint',
        meta: { title: "Imprimir Relatório de Alunos - Contato" },
        component: () => import('@pages/Impressao/Relatorio/Cadastro/Aluno/Contato')
    },

    {
        path: 'relatorio/lista-professor',
        name: 'RelatorioListaProfessorPrint',
        meta: { title: "Imprimir Relatório de Professores" },
        component: () => import('@pages/Impressao/Relatorio/Cadastro/Professor/ListaProfessor')
    },
    {
        path: 'relatorio/professor-completo',
        name: 'RelatorioProfessorCompletoPrint',
        meta: { title: "Imprimir Relatório de Professores Completo" },
        component: () => import('@pages/Impressao/Relatorio/Cadastro/Professor/ProfessorCompleto')
    },
    {
        path: 'relatorio/professor-aniversariante',
        name: 'RelatorioProfessorAniversariantePrint',
        meta: { title: "Imprimir Relatório de Professores Aniversariantes" },
        component: () => import('@pages/Impressao/Relatorio/Cadastro/Professor/Aniversariante')
    },

    {
        path: 'relatorio/lista-funcionario',
        name: 'RelatorioListaFuncionarioPrint',
        meta: { title: "Imprimir Relatório de Funcionários" },
        component: () => import('@pages/Impressao/Relatorio/Cadastro/Funcionario/ListaFuncionario')
    },
    {
        path: 'relatorio/funcionario-completo',
        name: 'RelatorioFuncionarioCompletoPrint',
        meta: { title: "Imprimir Relatório de Funcionários Completo" },
        component: () => import('@pages/Impressao/Relatorio/Cadastro/Funcionario/FuncionarioCompleto')
    },
    {
        path: 'relatorio/funcionario-perfil',
        name: 'RelatorioFuncionarioPerfilPrint',
        meta: { title: "Imprimir Relatório de Funcionários por Perfil" },
        component: () => import('@pages/Impressao/Relatorio/Cadastro/Funcionario/FuncionarioPerfil')
    },
    {
        path: 'relatorio/funcionario-aniversariante',
        name: 'RelatorioFuncionarioAniversariantePrint',
        meta: { title: "Imprimir Relatório de Funcionários Aniversariantes" },
        component: () => import('@pages/Impressao/Relatorio/Cadastro/Funcionario/Aniversariante')
    },
    {
        path: 'relatorio/lista-cliente',
        name: 'RelatorioListaClientePrint',
        meta: { title: "Imprimir Relatório de Clientes" },
        component: () => import('@pages/Impressao/Relatorio/Cadastro/Cliente/ListaCliente')
    },
    {
        path: 'relatorio/cliente-completo',
        name: 'RelatorioClienteCompletoPrint',
        meta: { title: "Imprimir Relatório de Cliente Completo" },
        component: () => import('@pages/Impressao/Relatorio/Cadastro/Cliente/ClienteCompleto')
    },
    {
        path: 'relatorio/lista-fornecedor',
        name: 'RelatorioListaFornecedorPrint',
        meta: { title: "Imprimir Relatório de Fornecedores" },
        component: () => import('@pages/Impressao/Relatorio/Cadastro/Fornecedor/ListaFornecedor')
    },
    {
        path: 'relatorio/fornecedor-completo',
        name: 'RelatorioFornecedorCompletoPrint',
        meta: { title: "Imprimir Relatório de Fornecedores Completo" },
        component: () => import('@pages/Impressao/Relatorio/Cadastro/Fornecedor/FornecedorCompleto')
    },
    {
        path: 'relatorio/material/almoxarifado',
        name: 'RelatorioMaterialAlmoxarifadoPrint',
        meta: { title: "Imprimir Relatório do Almoxarifado" },
        component: () => import('@pages/Impressao/Relatorio/Cadastro/Material/Almoxarifado')
    },
    {
        path: 'relatorio/material/estoque-minimo',
        name: 'RelatorioMaterialEstoqueMinimoPrint',
        meta: { title: "Imprimir Relatório do Estoque Mínimo de Material" },
        component: () => import('@pages/Impressao/Relatorio/Cadastro/Material/EstoqueMinimo')
    },
    {
        path: 'relatorio/material/movimentacao',
        name: 'RelatorioMaterialMovimentacaoPrint',
        meta: { title: "Imprimir Relatório de Materiais - Movimentação" },
        component: () => import('@pages/Impressao/Relatorio/Cadastro/Material/Movimentacao')
    },
    {
        path: 'relatorio/material/materiais-aluno',
        name: 'RelatorioMaterialAlunoPrint',
        meta: { title: "Imprimir Relatório de Materiais por Aluno" },
        component: () => import('@pages/Impressao/Relatorio/Cadastro/Material/MateriaisAluno')
    },

    {
        path: 'relatorio/servico/entidade',
        name: 'RelatorioServicoEntidadePrint',
        meta: { title: "Imprimir Relatório de Entidades por Serviço" },
        component: () => import('@pages/Impressao/Relatorio/Administrativo/Servico/Entidade')
    },
    {
        path: 'relatorio/servico/recebimento',
        name: 'RelatorioServicoRecebimentoPrint',
        meta: { title: "Imprimir Relatório de Recebimento de Entidades por Serviço" },
        component: () => import('@pages/Impressao/Relatorio/Administrativo/Servico/Recebimento')
    },
    {
        path: 'relatorio/venda',
        name: 'RelatorioListaVendaPrint',
        meta: { title: "Imprimir Relatório de Vendas" },
        component: () => import('@pages/Impressao/Relatorio/Administrativo/Venda/ListaVenda')
    },
    {
        path: 'relatorio/venda/produto',
        name: 'RelatorioVendaProdutoPrint',
        meta: { title: "Imprimir Relatório de Vendas por Produto" },
        component: () => import('@pages/Impressao/Relatorio/Administrativo/Venda/VendaProduto')
    },
    {
        path: 'relatorio/venda/aluno',
        name: 'RelatorioVendaAlunoPrint',
        meta: { title: "Imprimir Relatório de Vendas por Aluno" },
        component: () => import('@pages/Impressao/Relatorio/Administrativo/Venda/VendaAluno')
    },
      {
        path: 'relatorio/venda/turma',
        name: 'RelatorioVendaTurmaPrint',
        meta: { title: "Imprimir Relatório de Vendas por Turma" },
        component: () => import('@pages/Impressao/Relatorio/Administrativo/Venda/VendaTurma')
    },
    {
        path: 'relatorio/registro-aula',
        name: 'RelatorioRegistroAulaPrint',
        meta: { title: "Imprimir Relatório de Registro de Aula" },
        component: () => import('@pages/Impressao/Relatorio/Academico/RegistroAula/RegistroAula')
    },
    {
        path: 'relatorio/dia-letivo',
        name: 'RelatorioDiaLetivoPrint',
        meta: { title: "Imprimir Relatório de Dia Letivo" },
        component: () => import('@pages/Impressao/Relatorio/Academico/RegistroAula/DiaLetivo')
    },
    {
        path: 'relatorio/frequencia/disciplina',
        name: 'RelatorioFrequenciaDisciplinaPrint',
        meta: { title: "Imprimir Relatório de Frequência por Disciplina" },
        component: () => import('@pages/Impressao/Relatorio/Academico/Frequencia/FrequenciaDisciplina')
    },
    {
        path: 'relatorio/frequencia/geral',
        name: 'RelatorioFrequenciaGeralPrint',
        meta: { title: "Imprimir Relatório de Frequência Geral" },
        component: () => import('@pages/Impressao/Relatorio/Academico/Frequencia/FrequenciaGeral')
    },
    {
        path: 'relatorio/frequencia/dia',
        name: 'RelatorioFrequenciaDiaPrint',
        meta: { title: "Imprimir Relatório de Frequência por Dia" },
        component: () => import('@pages/Impressao/Relatorio/Academico/Frequencia/FrequenciaDia')
    },
    {
        path: 'relatorio/matricula/turma',
        name: 'RelatorioMatriculaTurmaPrint',
        meta: { title: "Imprimir Relatório de Matrícula por Turma" },
        component: () => import('@pages/Impressao/Relatorio/Matricula/Matricula/MatriculaTurma')
    },
    {
        path: 'relatorio/matricula/curso',
        name: 'RelatorioMatriculaCursoPrint',
        meta: { title: "Imprimir Relatório de Matrícula por Curso" },
        component: () => import('@pages/Impressao/Relatorio/Matricula/Matricula/MatriculaCurso')
    },
    {
        path: 'relatorio/personalizado',
        name: 'RelatorioPersonalizadoDeclaracaoFrequenciaPrint',
        meta: { title: "Imprimir Relatório Personalizado de Declaração de Frequência" },
        component: () => import('@pages/Impressao/Relatorio/Matricula/Personalizado/DeclaracaoFrequencia')
    },
    {
        path: 'relatorio/conta-corrente/saldo',
        name: 'RelatorioContaCorrenteSaldoPrint',
        meta: { title: "Imprimir Relatório de Conta Corrente - Saldo" },
        component: () => import('@pages/Impressao/Relatorio/Financeiro/ContaCorrente/Saldo')
    },
    {
        path: 'relatorio/conta-corrente/extrato',
        name: 'RelatorioContaCorrenteExtratoPrint',
        meta: { title: "Imprimir Relatório de Conta Corrente - Extrato" },
        component: () => import('@pages/Impressao/Relatorio/Financeiro/ContaCorrente/Extrato')
    },
    {
        path: 'relatorio/lancamento/receita',
        name: 'RelatorioLancamentoReceitaPrint',
        meta: { title: "Imprimir Relatório de Lançamento - Receita" },
        component: () => import('@pages/Impressao/Relatorio/Financeiro/Lancamento/Receita')
    },
    {
        path: 'relatorio/lancamento/receita-carteira',
        name: 'RelatorioLancamentoReceitaCarteiraPrint',
        meta: { title: "Imprimir Relatório de Lançamento - Receita Carteira" },
        component: () => import('@pages/Impressao/Relatorio/Financeiro/Lancamento/ReceitaCarteira')
    },
    {
        path: 'relatorio/lancamento/conta-receber',
        name: 'RelatorioLancamentoContaReceberPrint',
        meta: { title: "Imprimir Relatório de Lançamento - Conta Receber" },
        component: () => import('@pages/Impressao/Relatorio/Financeiro/Lancamento/ContaReceber')
    },
    {
        path: 'relatorio/lancamento/receita-plano',
        name: 'RelatorioLancamentoReceitaPlanoPrint',
        meta: { title: "Imprimir Relatório de Lançamento - Receita por Plano Gerencial" },
        component: () => import('@pages/Impressao/Relatorio/Financeiro/Lancamento/ReceitaPlano')
    },
    {
        path: 'relatorio/lancamento/receita-turma',
        name: 'RelatorioLancamentoReceitaTurmaPrint',
        meta: { title: "Imprimir Relatório de Lançamento - Receita por Turma" },
        component: () => import('@pages/Impressao/Relatorio/Financeiro/Lancamento/ReceitaTurma')
    },
    {
        path: 'relatorio/lancamento/despesa',
        name: 'RelatorioLancamentoDespesaPrint',
        meta: { title: "Imprimir Relatório de Lançamento - Despesa" },
        component: () => import('@pages/Impressao/Relatorio/Financeiro/Lancamento/Despesa')
    },
    {
        path: 'relatorio/lancamento/despesa-carteira',
        name: 'RelatorioLancamentoDespesaCarteiraPrint',
        meta: { title: "Imprimir Relatório de Lançamento - Despesa por Carteira" },
        component: () => import('@pages/Impressao/Relatorio/Financeiro/Lancamento/DespesaCarteira')
    },
    {
        path: 'relatorio/lancamento/conta-pagar',
        name: 'RelatorioLancamentoContaPagarPrint',
        meta: { title: "Imprimir Relatório de Lançamento - Contas a Pagar" },
        component: () => import('@pages/Impressao/Relatorio/Financeiro/Lancamento/ContaPagar')
    },
    {
        path: 'relatorio/lancamento/despesa-categoria',
        name: 'RelatorioLancamentoDespesaCategoriaPrint',
        meta: { title: "Imprimir Relatório de Lançamento - Despesa por Categoria" },
        component: () => import('@pages/Impressao/Relatorio/Financeiro/Lancamento/DespesaCategoria')
    },
    {
        path: 'relatorio/lancamento/movimentacao-diaria',
        name: 'RelatorioLancamentoMovimentacaoDiariaPrint',
        meta: { title: "Imprimir Relatório de Lançamento - Movimentação Diária" },
        component: () => import('@pages/Impressao/Relatorio/Financeiro/Lancamento/MovimentacaoDiaria')
    },
    {
        path: 'relatorio/lancamento/fluxo-caixa',
        name: 'RelatorioLancamentoFluxoCaixaPrint',
        meta: { title: "Imprimir Relatório de Lançamento - Fluxo Caixa" },
        component: () => import('@pages/Impressao/Relatorio/Financeiro/Lancamento/FluxoCaixa')
    },
    {
        path: 'relatorio/boleto/boleto-bancario',
        name: 'RelatorioBoletoRetornoBancarioPrint',
        meta: { title: "Imprimir Relatório de Boleto - Retorno Bancário" },
        component: () => import('@pages/Impressao/Relatorio/Financeiro/Boleto/RetornoBancario')
    },
    {
        path: 'relatorio/financeiro-aluno/inadimplencia',
        name: 'RelatorioFinanceiroAlunoInadimplenciaPrint',
        meta: { title: "Imprimir Relatório de Financeiro Aluno - Inadimplência" },
        component: () => import('@pages/Impressao/Relatorio/Financeiro/FinanceiroAluno/Inadimplencia')
    },
    {
        path: 'relatorio/financeiro-aluno/desconto',
        name: 'RelatorioFinanceiroAlunoDescontoPrint',
        meta: { title: "Imprimir Relatório de Financeiro Aluno - Desconto" },
        component: () => import('@pages/Impressao/Relatorio/Financeiro/FinanceiroAluno/Desconto')
    },
]
