export default [
    {
        path: 'calendarioeventos',
        name: 'CalendarioEventos',
        meta: { title: "Calendário de Eventos" },
        component: () => import('@pages/Comunicacao/CalendarioEventos')
    },
    {
        path: 'eventos',
        name: 'CalendarioEventoList',
        meta: { title: "Eventos" },
        component: () => import('@pages/Comunicacao/Evento')
    },
    {
        path: 'eventos/create',
        name: 'CalendarioEventoCreate',
        meta: { title: "Cadastro de Evento" },
        component: () => import('@pages/Comunicacao/EventoCreate')
    },
    {
        path: 'eventos/edit/:id',
        props: true,
        name: 'CalendarioEventoEdit',
        meta: { title: "Edição de Evento" },
        component: () => import('@pages/Comunicacao/EventoEdit')
    },
    {
        path: 'comunicados',
        name: 'ComunicadoList',
        meta: { title: "Comunicados" },
        component: () => import('@pages/Comunicacao/Comunicado')
    },
    {
        path: 'comunicados/create',
        name: 'ComunicadoCreate',
        meta: { title: "Cadastro de Comunicado" },
        component: () => import('@pages/Comunicacao/ComunicadoCreate')
    },
    {
        path: 'comunicados/edit/:id',
        props: true,
        name: 'ComunicadoEdit',
        meta: { title: "Edição de Comunicado" },
        component: () => import('@pages/Comunicacao/ComunicadoEdit')
    },
    {
        path: 'comunicados/show/:id',
        props: true,
        name: 'ComunicadoShow',
        meta: { title: "Visualização de Comunicado" },
        component: () => import('@pages/Comunicacao/ComunicadoShow')
    },
    {
        path: 'comunicados/envio/:id',
        props: true,
        name: 'ComunicadoEnvio',
        meta: { title: "Enviar Comunicado" },
        component: () => import('@pages/Comunicacao/ComunicadoEnvio')
    },
    {
        path: 'comunicados/confirmacao/:id',
        props: true,
        name: 'ComunicadoConfirmacao',
        meta: { title: "Enviar Comunicado" },
        component: () => import('@pages/Comunicacao/ComunicadoConfirmacao')
    },
    {
        path: 'evento',
        name: 'EventoList',
        meta: { title: "Eventos" },
        component: () => import('@pages/Evento/Evento')
    },
    {
        path: 'evento/create',
        name: 'EventoCreate',
        meta: { title: "Cadastro de Evento" },
        component: () => import('@pages/Evento/EventoCreate')
    },
    {
        path: 'evento/edit/:id',
        props: true,
        name: 'EventoEdit',
        meta: { title: "Edição de Evento" },
        component: () => import('@pages/Evento/EventoEdit')
    },
    {
        path: 'agendaescolar',
        name: 'AgendaEscolarList',
        meta: { title: " Agenda Escolar" },
        component: () => import('@pages/AgendaEscolar/AgendaEscolar')
    },
    {
        path: 'agendaescolar/create',
        props: true,
        name: 'AgendaEscolarCreate',
        meta: { title: "Cadastro de Agenda Escolar"},
        component: () => import('@pages/AgendaEscolar/AgendaEscolarCreate')
    },
    {
        path: 'agendaescolar/edit/:id',
        props: true,
        name: 'AgendaEscolarEdit',
        meta: { title: "Edição de Agenda Escolar"},
        component: () => import('@pages/AgendaEscolar/AgendaEscolarEdit')
    },
    {
        path: 'agendaescolar/show/:id',
        props: true,
        name: 'AgendaEscolarShow',
        meta: { title: "Visualizar Agenda Escolar"},
        component: () => import('@pages/AgendaEscolar/AgendaEscolarShow')
    },
    {
        path: 'agendaescolar/midia/:agenda_escolar_id',
        props: true,
        name: 'AgendaEscolarMidia',
        meta: { title: "Mídia de Agenda Escolar"},
        component: () => import('@pages/AgendaEscolar/AgendaEscolarMidia')
    },
]
