import axios from "axios";
import router from "../router/router";
import store from "../store/store";
import { clearData } from "../services/clearData";

let Api = axios.create({
    baseURL: process.env.MIX_APP_URL+"/api"
});

Api.defaults.withCredentials = true;

let requestCount = 0;

Api.interceptors.request.use(
  (config) => {
    requestCount++;
    if (requestCount === 1) {
      store.commit('setLoading', true);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Api.interceptors.response.use(
  (response) => {
    requestCount--;
    if (requestCount === 0) {
      store.commit('setLoading', false);
    }
    return response;
  },
  (error) => {
    requestCount--;
    if (requestCount === 0) {
      store.commit('setLoading', false);
    }

    if (error.response.status === 401) {
      router.push({ name: 'Login' });
    } else {
      let msgError = [];
      if (error.response.status === 422) {
        msgError = error.response.data.errors;
      } else {
        msgError = [['Houve um erro no servidor']];
      }
      store.commit('setErrorMsg', msgError);
    }
    return Promise.reject(error);
  }
);

export default Api;
