export default [
    {
        path: 'aluno/cadastro',
        props: true,
        name: 'AlunoCadastro',
        meta: { title: "Meu Cadastro" },
        component: () => import('@pages/Academico/AlunoCadastro')
    },
    {
        path: 'aluno/senha/:id',
        props: true,
        name: 'AlunoSenhaEdit',
        meta: { title: "Alterar Senha" },
        component: () => import('@pages/Academico/AlunoSenhaEdit')
    },
    {
        path: 'aluno-responsavel/senha/:id',
        props: true,
        name: 'ResponsavelSenhaEdit',
        meta: { title: "Alterar Senha" },
        component: () => import('@pages/Academico/ResponsavelSenhaEdit')
    },
    {
        path: 'responsavel',
        name: 'ResponsavelPage',
        meta: {title: "Escolha"},
        component: () => import('@pages/Academico/ResponsavelPage')
    },
    {
        path: 'academico',
        name: 'AcademicoHome',
        meta: {title: "Início"},
        component: () => import('@pages/Academico/Home')
    },
    {
        path: 'academico/matricula/:id',
        props: true,
        name: 'CursoMatriculaList',
        meta: {title: "Rematrículas"},
        component: () => import('@pages/Academico/CursoMatriculaList')
    },
    {
        path: 'academico/cadastro',
        name: 'AcademicoInfo',
        meta: {title: "Cadastro"},
        component: () => import('@pages/Academico/Info')
    },
    {
        path: 'academico/boletim',
        name: 'BoletimList',
        meta: {title: "Boletim"},
        component: () => import('@pages/Academico/BoletimList')
    },
    {
        path: 'academico/registro-aula',
        name: 'RegistroAulaAluno',
        meta: {title: "Registros de Aula"},
        component: () => import('@pages/Academico/RegistroAulaAluno')
    },
    {
        path: 'agendaescolar',
        name: 'AgendaEscolarAluno',
        meta: { title: "Agenda Escolar" },
        component: () => import('@pages/Academico/AgendaEscolarAluno')
    },
    {
        path: 'academico/boletim/show/:id',
        props: true,
        name: 'BoletimShow',
        meta: {title: "Visualizar Boletim"},
        component: () => import('@pages/Academico/BoletimShow')
    },
    {
        path: 'academico/calendario',
        name: 'AcademicoCalendario',
        meta: {title: "Calendário"},
        component: () => import('@pages/Academico/Calendario')
    },
    {
        path: 'rematricula-aluno',
        name: 'RematriculaList',
        meta: { title: "Rematrícula" },
        component: () => import('@pages/Academico/RematriculaList')
    },
    {
        path: 'rematricula-aluno-confirmar',
        name: 'RematriculaConfirm',
        meta: { title: "Rematrícula" },
        component: () => import('@pages/Academico/RematriculaConfirm')
    },
    {
        path: 'academico/ocorrencia',
        name: 'AcademicoOcorrencia',
        meta: {title: "Ocorrências"},
        component: () => import('@pages/Academico/Ocorrencia')
    },
    {
        path: 'academico/comunicado',
        name: 'AcademicoComunicado',
        meta: {title: "Comunicados"},
        component: () => import('@pages/Academico/Comunicado')
    },
    {
        path: 'academico/financeiro',
        name: 'AcademicoFinanceiro',
        meta: {title: "Financeiro"},
        component: () => import('@pages/Academico/Financeiro')
    },
    {
        path: 'horarios',
        name: 'HorarioAulaAluno',
        meta: {title: "Horarios"},
        component: () => import('@pages/Academico/HorarioAulaAluno')
    },
]
