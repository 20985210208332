export default [
    {
        path: '',
        name: 'HomeSite',
        meta: {title: "Início"},
        component: () => import('@pages/Site/Home')
    },
    {
        path: '/noticia',
        name: 'NoticiaSite',
        meta: {title: "EEMAN - Notícias"},
        component: () => import('@pages/Site/Noticia')
    },
    {
        path: '/noticia/noticia-page',
        name: 'NoticiaPageSite',
        meta: {title: "EEMAN - Notícias"},
        component: () => import('@pages/Site/NoticiaPage')
    },
    {
        path: '/curso',
        name: 'CursoSite',
        meta: {title: "EEMAN - Cursos"},
        component: () => import('@pages/Site/Curso')
    },
]
