import Vue from 'vue'
import Vuex from 'vuex'
import staticData from '@api/staticData'

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

const store = new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        cor: null,
        logo: null,
        isLogged: false,
        user: null,
        loading: false,
        errorMsg: false,
        listaBanco: [],
        listaEstados: [],
        listaEstadoCivil: [],
        listaGrauInstrucao: [],
        listaGrauParentesco: [],
        listaNacionalidade: [],
        listaOrgaoEmissor: [],
        listaRacaCor: [],
        listaReligiao: [],
        listaEnvioComunicado: [],
        optionsMatricula: {},
        menuOpen: false,
        alunosModal: false,
        periodoLetivoAtivo: {},
        //created
        optionsAdministrativoTurma: {
            itemsPerPage: 25,
                search: '',
                with: [
                    'curso',
                ],
                withCount: [
                    'matriculasativas',
                ],
                sortBy: ['ordem'],
                sortDesc: true,
        },
        optionsAcademicoMatricula: {
            itemsPerPage: 25,
            search: '',
            with: [
                'aluno.entidade',
                'turma.curso'
            ]
        },
        optionsAgendaEscolar: {
            itemsPerPage: 25,
            search: '',
            with: [
                'professor.entidade',
                'turma.curso',
                'disciplina',
            ]
        },
        boleto: '',
        optionsRelatorioAluno:{},
        optionsRelatorioAlunoCompleto:{},
        optionsRelatorioAlunoMatriculaResponsavel:{},
        optionsRelatorioAlunoResponsavel:{},
        optionsRelatorioAlunoAplicativo:{},
        optionsRelatorioAlunoDocumento:{},
        optionsRelatorioAlunoTelefone:{},
        optionsRelatorioAlunoContato:{},
        optionsRelatorioProfessor:{},
        optionsRelatorioProfessorAniversariante:{},
        optionsRelatorioFuncionarioAniversariante:{},
        optionsRelatorioFuncionarioPerfil:{},
        optionsRelatorioCliente:{},
        optionsRelatorioClienteCompleto:{},
        optionsRelatorioFornecedor:{},
        optionsRelatorioFornecedorCompleto:{},
        optionsRelatorioMaterialMovimentacao:{},
        optionsRelatorioMaterialAluno:{},
        optionsRelatorioServicoEntidade:{},
        optionsRelatorioServicoEntidadeRecebimento:{},
        optionsRelatorioServicoServico:{},
        optionsRelatorioVenda:{},
        optionsRelatorioVendaProduto:{},
        optionsRelatorioVendaAluno:{},
        optionsRelatorioVendaTurma:{},
        optionsRelatorioRegistroAula:{},
        optionsRelatorioDiaLetivo:{},
        optionsRelatorioFrequenciaDisciplina:{},
        optionsRelatorioFrequenciaGeral:{},
        optionsRelatorioPersonalizadoDeclaracaoFrequencia:{},
        optionsRelatorioContaCorrenteSaldo:{},
        optionsRelatorioLancamentoReceita:{},
        optionsRelatorioLancamentoReceitaCarteira:{},
        optionsRelatorioLancamentoContaReceber:{},
        optionsRelatorioLancamentoReceitaPlano:{},
        optionsRelatorioLancamentoReceitaTurma:{},
        optionsRelatorioLancamentoDespesa:{},
        optionsRelatorioLancamentoDespesaCarteira:{},
        optionsRelatorioLancamentoContaPagar:{},
        optionsRelatorioLancamentoDespesaCategoria:{},
        optionsRelatorioLancamentoMovimentacaoDiaria:{},
        optionsRelatorioLancamentoFluxoCaixa:{},
        optionsRelatorioBoletoRetornoBancario:{},
        optionsRelatorioFinanceiroAlunoInadimplencia:{},
        optionsRelatorioFinanceiroAlunoDesconto:{},
        optionsRelatorioMatriculaTurma:{},
        optionsRelatorioMatriculaCurso:{},
        optionsContaReceber: null,
        optionsContaPagar: null,
        configAcademico: {},
    },
    getters: {
        getMenuOpen(state) {
            return state.menuOpen;
        },
        getLoading(state){
            return state.loading;
        },
        getPeriodoLetivoAtivo(state){
            return state.periodoLetivoAtivo;
        },
        getErrorMsg(state){
            return state.errorMsg;
        },
        getModalAluno(state){
            return state.alunosModal;
        },
        getConfigAcademico(state){
            return state.configAcademico;
        },
    },
    mutations : {
        resetState: (state) => {
            Object.assign(state, {
                cor: null,
                logo: null,
                isLogged: false,
                user: null,
                loading: false,
                errorMsg: false,
                listaBanco: [],
                listaEstados: [],
                listaEstadoCivil: [],
                listaGrauInstrucao: [],
                listaGrauParentesco: [],
                listaNacionalidade: [],
                listaOrgaoEmissor: [],
                listaRacaCor: [],
                listaReligiao: [],
                listaEnvioComunicado: [],
                optionsMatricula: {},
                menuOpen: false,
                alunosModal: false,
                periodoLetivoAtivo: {},
                //created
                optionsAdministrativoTurma: {
                    itemsPerPage: 25,
                    search: '',
                    with: [
                        'curso',
                        'matriculasativas.aluno.entidade'
                    ],
                    sortBy: ['curso.ordem'],
                    sortDesc: true,
                },
                optionsAcademicoMatricula: {
                    itemsPerPage: 25,
                    search: '',
                    with: [
                        'aluno.entidade',
                        'turma.curso'
                    ]
                },
                optionsAgendaEscolar: {
                    itemsPerPage: 25,
                    search: '',
                    with: [
                        'professor.entidade',
                        'turma.curso',
                        'disciplina',
                    ]
                },
                boleto: '',
                optionsRelatorioAluno:{},
                optionsRelatorioAlunoCompleto:{},
                optionsRelatorioAlunoMatriculaResponsavel:{},
                optionsRelatorioAlunoResponsavel:{},
                optionsRelatorioAlunoAplicativo:{},
                optionsRelatorioAlunoDocumento:{},
                optionsRelatorioAlunoTelefone:{},
                optionsRelatorioAlunoContato:{},
                optionsRelatorioProfessor:{},
                optionsRelatorioProfessorAniversariante:{},
                optionsRelatorioFuncionarioAniversariante:{},
                optionsRelatorioFuncionarioPerfil:{},
                optionsRelatorioCliente:{},
                optionsRelatorioClienteCompleto:{},
                optionsRelatorioFornecedor:{},
                optionsRelatorioFornecedorCompleto:{},
                optionsRelatorioMaterialMovimentacao:{},
                optionsRelatorioMaterialAluno:{},
                optionsRelatorioServicoEntidade:{},
                optionsRelatorioServicoEntidadeRecebimento:{},
                optionsRelatorioServicoServico:{},
                optionsRelatorioVenda:{},
                optionsRelatorioVendaProduto:{},
                optionsRelatorioVendaAluno:{},
                optionsRelatorioVendaTurma:{},
                optionsRelatorioRegistroAula:{},
                optionsRelatorioDiaLetivo:{},
                optionsRelatorioFrequenciaDisciplina:{},
                optionsRelatorioFrequenciaGeral:{},
                optionsRelatorioPersonalizadoDeclaracaoFrequencia:{},
                optionsRelatorioContaCorrenteSaldo:{},
                optionsRelatorioLancamentoReceita:{},
                optionsRelatorioLancamentoReceitaCarteira:{},
                optionsRelatorioLancamentoContaReceber:{},
                optionsRelatorioLancamentoReceitaPlano:{},
                optionsRelatorioLancamentoReceitaTurma:{},
                optionsRelatorioLancamentoDespesa:{},
                optionsRelatorioLancamentoDespesaCarteira:{},
                optionsRelatorioLancamentoContaPagar:{},
                optionsRelatorioLancamentoDespesaCategoria:{},
                optionsRelatorioLancamentoMovimentacaoDiaria:{},
                optionsRelatorioLancamentoFluxoCaixa:{},
                optionsRelatorioBoletoRetornoBancario:{},
                optionsRelatorioFinanceiroAlunoInadimplencia:{},
                optionsRelatorioFinanceiroAlunoDesconto:{},
                optionsRelatorioMatriculaTurma:{},
                optionsRelatorioMatriculaCurso:{},
                optionsContaReceber: null,
                optionsContaPagar: null,
                configAcademico: {},
            });
        },
        isLogged(state, payload){
            state.isLogged = payload
        },
        setUser(state, payload){
            state.user = payload
        },
        setAluno(state, payload){
            state.user.entidade.aluno = payload
        },
        setListaBanco(state, payload){
            state.listaBanco = payload
        },
        setListaEstados(state, payload){
            state.listaEstados = payload
        },
        setListaEstadoCivil(state, payload){
            state.listaEstadoCivil = payload
        },
        setListaGrauInstrucao(state, payload){
            state.listaGrauInstrucao = payload
        },
        setListaGrauParentesco(state, payload){
            state.listaGrauParentesco = payload
        },
        setListaNacionalidade(state, payload){
            state.listaNacionalidade = payload
        },
        setListaOrgaoEmissor(state, payload){
            state.listaOrgaoEmissor = payload
        },
        setListaRacaCor(state, payload){
            state.listaRacaCor = payload
        },
        setListaReligiao(state, payload){
            state.listaReligiao = payload
        },
        setListaEnvioComunicado(state, payload){
            state.listaEnvioComunicado = payload
        },
        setOptionsMatricula(state, payload){
            state.optionsMatricula = payload
        },
        setLoading(state, payload){
            state.loading = payload
        },
        setPeriodoLetivoAtivo(state, payload){
            state.periodoLetivoAtivo = payload
        },
        setErrorMsg(state, payload){
            state.errorMsg = payload
        },
        setModalAluno(state, payload){
            state.alunosModal = payload
        },
        //watch
        setOptionsAdministrativoTurma(state, payload){
            state.optionsAdministrativoTurma = payload
        },
        setOptionsContaReceber(state, payload){
            state.optionsContaReceber = payload
        },
        setOptionsContaPagar(state, payload){
            state.optionsContaPagar = payload
        },
        setOptionsAgendaEscolar(state, payload){
            state.optionsAgendaEscolar = payload
        },
        setOptionsAcademicoMatricula(state, payload){
            state.optionsAcademicoMatricula = payload
        },
        setOptionsRelatorioAluno(state, payload){
            state.optionsRelatorioAluno = payload
        },
        setOptionsRelatorioAlunoCompleto(state, payload){
            state.optionsRelatorioAlunoCompleto = payload
        },
        setOptionsRelatorioAlunoMatriculaResponsavel(state, payload){
            state.optionsRelatorioAlunoMatriculaResponsavel = payload
        },
        setOptionsRelatorioAlunoResponsavel(state, payload){
            state.optionsRelatorioAlunoResponsavel = payload
        },
        setOptionsRelatorioAlunoAplicativo(state, payload){
            state.optionsRelatorioAlunoAplicativo = payload
        },
        setOptionsRelatorioAlunoDocumento(state, payload){
            state.optionsRelatorioAlunoDocumento = payload
        },
        setOptionsRelatorioAlunoTelefone(state, payload){
            state.optionsRelatorioAlunoTelefone = payload
        },
        setOptionsRelatorioAlunoContato(state, payload){
            state.optionsRelatorioAlunoContato = payload
        },
        setOptionsRelatorioProfessorAniversariante(state, payload){
            state.optionsRelatorioProfessorAniversariante = payload
        },
        setOptionsRelatorioProfessor(state, payload){
            state.optionsRelatorioProfessor = payload
        },
        setOptionsRelatorioFuncionarioAniversariante(state, payload){
            state.optionsRelatorioFuncionarioAniversariante = payload
        },
        setOptionsRelatorioFuncionarioPerfil(state, payload){
            state.optionsRelatorioFuncionarioPerfil = payload
        },
        setOptionsRelatorioCliente(state, payload){
            state.optionsRelatorioCliente = payload
        },
        setOptionsRelatorioClienteCompleto(state, payload){
            state.optionsRelatorioClienteCompleto = payload
        },
        setOptionsRelatorioFornecedor(state, payload){
            state.optionsRelatorioFornecedor = payload
        },
        setOptionsRelatorioFornecedorCompleto(state, payload){
            state.optionsRelatorioFornecedorCompleto = payload
        },
        setOptionsRelatorioMaterialAluno(state, payload){
            state.optionsRelatorioMaterialAluno = payload
        },
        setOptionsRelatorioMaterialMovimentacao(state, payload){
            state.optionsRelatorioMaterialMovimentacao = payload
        },
        setOptionsRelatorioServicoEntidade(state, payload){
            state.optionsRelatorioServicoEntidade = payload
        },
        setOptionsRelatorioServicoEntidadeRecebimento(state, payload){
            state.optionsRelatorioServicoEntidadeRecebimento = payload
        },
        setOptionsRelatorioServicoServico(state, payload){
            state.optionsRelatorioServicoServico = payload
        },
        setOptionsRelatorioVenda(state, payload){
            state.optionsRelatorioVenda = payload
        },
        setOptionsRelatorioVendaProduto(state, payload){
            state.optionsRelatorioVendaProduto = payload
        },
        setOptionsRelatorioVendaAluno(state, payload){
            state.optionsRelatorioVendaAluno = payload
        },
        setOptionsRelatorioVendaTurma(state, payload){
            state.optionsRelatorioVendaTurma = payload
        },
        setOptionsRelatorioRegistroAula(state, payload){
            state.optionsRelatorioRegistroAula = payload
        },
        setOptionsRelatorioDiaLetivo(state, payload){
            state.optionsRelatorioDiaLetivo = payload
        },
        setOptionsRelatorioFrequenciaDisciplina(state, payload){
            state.optionsRelatorioFrequenciaDisciplina = payload
        },
        setOptionsRelatorioFrequenciaGeral(state, payload){
            state.optionsRelatorioFrequenciaGeral = payload
        },
        setOptionsRelatorioPersonalizadoDeclaracaoFrequencia(state, payload){
            state.optionsRelatorioPersonalizadoDeclaracaoFrequencia = payload
        },
        setOptionsRelatorioContaCorrenteSaldo(state, payload){
            state.optionsRelatorioContaCorrenteSaldo = payload
        },
        setOptionsRelatorioContaCorrenteExtrato(state, payload){
            state.optionsRelatorioContaCorrenteExtrato = payload
        },
        setOptionsRelatorioLancamentoReceita(state, payload){
            state.optionsRelatorioLancamentoReceita = payload
        },
        setOptionsRelatorioLancamentoReceitaCarteira(state, payload){
            state.optionsRelatorioLancamentoReceitaCarteira = payload
        },
        setOptionsRelatorioLancamentoContaReceber(state, payload){
            state.optionsRelatorioLancamentoContaReceber = payload
        },
        setOptionsRelatorioLancamentoReceitaPlano(state, payload){
            state.optionsRelatorioLancamentoReceitaPlano = payload
        },
        setOptionsRelatorioLancamentoReceitaTurma(state, payload){
            state.optionsRelatorioLancamentoReceitaTurma = payload
        },
        setOptionsRelatorioLancamentoDespesa(state, payload){
            state.optionsRelatorioLancamentoDespesa = payload
        },
        setOptionsRelatorioLancamentoDespesaCarteira(state, payload){
            state.optionsRelatorioLancamentoDespesaCarteira = payload
        },
        setOptionsRelatorioLancamentoContaPagar(state, payload){
            state.optionsRelatorioLancamentoContaPagar = payload
        },
        setOptionsRelatorioLancamentoDespesaCategoria(state, payload){
            state.optionsRelatorioLancamentoDespesaCategoria = payload
        },
        setOptionsRelatorioLancamentoMovimentacaoDiaria(state, payload){
            state.optionsRelatorioLancamentoMovimentacaoDiaria = payload
        },
        setOptionsRelatorioLancamentoFluxoCaixa(state, payload){
            state.optionsRelatorioLancamentoFluxoCaixa = payload
        },
        setOptionsRelatorioRetornoBancario(state, payload){
            state.optionsRelatorioRetornoBancario = payload
        },
        setOptionsRelatorioFinanceiroAlunoInadimplencia(state, payload){
            state.optionsRelatorioFinanceiroAlunoInadimplencia = payload
        },
        setOptionsRelatorioFinanceiroAlunoDesconto(state, payload){
            state.optionsRelatorioFinanceiroAlunoDesconto = payload
        },
        setOptionsRelatorioRelatorioMatriculaTurma(state, payload){
            state.optionsRelatorioMatriculaTurma = payload
        },
        setOptionsRelatorioRelatorioMatriculaCurso(state, payload){
            state.optionsRelatorioMatriculaCurso = payload
        },
        setConfigAcademico(state, payload){
            state.configAcademico = payload
        },
    },
    actions: {
        async loadStaticData({commit}){
            const listaStaticData = await staticData.getAll()

            commit('setListaBanco', listaStaticData.data.banco)
            commit('setListaEstadoCivil', listaStaticData.data.estadoCivil)
            commit('setListaEstados', listaStaticData.data.estados)
            commit('setListaGrauInstrucao', listaStaticData.data.grauInstrucao)
            commit('setListaGrauParentesco', listaStaticData.data.grauParentesco)
            commit('setListaNacionalidade', listaStaticData.data.nacionalidade)
            commit('setListaOrgaoEmissor', listaStaticData.data.orgaoEmissor)
            commit('setListaRacaCor', listaStaticData.data.racaCor)
            commit('setListaReligiao', listaStaticData.data.religiao)
        }
    },
})

export default store
