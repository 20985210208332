export default [
    {
        path: 'rematricula',
        name: 'RematriculaListAdm',
        meta: {title: "Rematrícula"},
        component: () => import('@pages/Rematricula/RematriculaList')
    },
    {
        path: 'rematricula/create',
        name: 'RematriculaCreateAdm',
        meta: {title: "Criar Rematrícula"},
        component: () => import('@pages/Rematricula/RematriculaCreate')
    },
    {
        path: 'rematricula/edit',
        props: true,
        name: 'RematriculaEditAdm',
        meta: {title: "Criar Rematrícula"},
        component: () => import('@pages/Rematricula/RematriculaEdit')
    },
    {
        path: 'bolsa',
        name: 'BolsaList',
        meta: {title: "Bolsas", permissions: ['bolsa_visualizar']},
        component: () => import('@pages/Bolsa/Bolsa')
    },
    {
        path: 'bolsa/create',
        name: 'BolsaCreate',
        meta: {title: "Cadastro de Bolsa", permissions: ['bolsa_inserir']},
        component: () => import('@pages/Bolsa/BolsaCreate')
    },
    {
        path: 'bolsa/edit/:id',
        props: true,
        name: 'BolsaEdit',
        meta: {title: "Edição de Bolsa", permissions: ['bolsa_editar']},
        component: () => import('@pages/Bolsa/BolsaEdit')
    },
    {
        path: 'tipocurso',
        name: 'TipoCursoList',
        meta: {title: "Tipos de Curso"},
        component: () => import('@pages/TipoCurso/TipoCursoList')
    },
    {
        path: 'tipocurso/create',
        name: 'TipoCursoCreate',
        meta: {title: "Cadastro de Tipo de Curso"},
        component: () => import('@pages/TipoCurso/TipoCursoCreate')
    },
    {
        path: 'tipocurso/edit/:id',
        props: true,
        name: 'TipoCursoEdit',
        meta: {title: "Edição de Tipo de Curso"},
        component: () => import('@pages/TipoCurso/TipoCursoEdit')
    },
    {
        path: 'modalidade',
        name: 'ModalidadeList',
        meta: {title: "Modalidades", permissions: ['modalidade_visualizar']},
        component: () => import('@pages/Modalidade/Modalidade')
    },
    {
        path: 'modalidade/create',
        name: 'ModalidadeCreate',
        meta: {title: "Cadastro de Modalidade", permissions: ['modalidade_inserir']},
        component: () => import('@pages/Modalidade/ModalidadeCreate')
    },
    {
        path: 'modalidade/edit/:id',
        props: true,
        name: 'ModalidadeEdit',
        meta: {title: "Edição de Modalidade", permissions: ['modalidade_editar']},
        component: () => import('@pages/Modalidade/ModalidadeEdit')
    },
    {
        path: 'compra',
        name: 'CompraList',
        meta: {title: "Compras"},
        component: () => import('@pages/Compra/CompraList')
    },
    {
        path: 'compra/create',
        props:true,
        name: 'CompraCreate',
        meta: {title: "Cadastro de Compra"},
        component: () => import('@pages/Compra/CompraCreate')
    },
    {
        path: 'compra/show/:id',
        props: true,
        name: 'CompraShow',
        meta: {title: "Visualizar Compra"},
        component: () => import('@pages/Compra/CompraShow')
    },
    {
        path: 'venda',
        name: 'VendaList',
        meta: {title: "Vendas"},
        component: () => import('@pages/Venda/VendaList')
    },
    {
        path: 'venda/create',
        props:true,
        name: 'VendaCreate',
        meta: {title: "Cadastro de Venda"},
        component: () => import('@pages/Venda/VendaCreate')
    },
    {
        path: 'venda/show/:id',
        props: true,
        name: 'VendaShow',
        meta: {title: "Visualizar Venda"},
        component: () => import('@pages/Venda/VendaShow')
    },
    {
        path: 'patrimonio',
        name: 'PatrimonioList',
        meta: {title: "Patrimonios", permissions: ['patrimonio_visualizar']},
        component: () => import('@pages/Patrimonio/Patrimonio')
    },
    {
        path: 'patrimonio/create',
        props: true,
        name: 'PatrimonioCreate',
        meta: {title: "Cadastro de Patrimonio", permissions: ['patrimonio_inserir']},
        component: () => import('@pages/Patrimonio/PatrimonioCreate')
    },
    {
        path: 'patrimonio/edit/:id',
        props: true,
        name: 'PatrimonioEdit',
        meta: {title: "Edição de Patrimonio", permissions: ['patrimonio_editar']},
        component: () => import('@pages/Patrimonio/PatrimonioEdit')
    },
    {
        path: 'patrimonio/show/:id',
        props: true,
        name: 'PatrimonioShow',
        meta: {title: "Visualização de Patrimonio", permissions: ['patrimonio_visualizar']},
        component: () => import('@pages/Patrimonio/PatrimonioShow')
    },

    {
        path: 'patrimoniotipo',
        name: 'PatrimonioTipoList',
        meta: {title: "Tipos", permissions: ['patrimonio_tipo_visualizar']},
        component: () => import('@pages/PatrimonioTipo/Tipo')
    },
    {
        path: 'patrimoniotipo/create',
        props:true,
        name: 'PatrimonioTipoCreate',
        meta: {title: "Cadastro de Tipos", permissions: ['patrimonio_tipo_inserir']},
        component: () => import('@pages/PatrimonioTipo/TipoCreate')
    },
    {
        path: 'patrimoniotipo/edit/:id',
        props: true,
        name: 'PatrimonioTipoEdit',
        meta: {title: "Edição de Tipos", permissions: ['patrimonio_tipo_editar']},
        component: () => import('@pages/PatrimonioTipo/TipoEdit')
    },
    {
        path: 'almoxarifado',
        name: 'AlmoxarifadoList',
        meta: {title: "Almoxarifado"},
        component: () => import('@pages/Almoxarifado/Almoxarifado')
    },
    {
        path: 'almoxarifado/entrada/:id',
        props: true,
        name: 'AlmoxarifadoEntrada',
        meta: {title: "Entrada de Almoxarifado"},
        component: () => import('@pages/Almoxarifado/AlmoxarifadoEntrada')
    },
    {
        path: 'almoxarifado/saida/:id',
        props: true,
        name: 'AlmoxarifadoSaida',
        meta: {title: "Saída de Almoxarifado"},
        component: () => import('@pages/Almoxarifado/AlmoxarifadoSaida')
    },
    {
        path: 'periodo-letivo/finalizar',
        props: true,
        name: 'PeriodoLetivoFinalizar',
        meta: {title: "Finalizar de Período Letivo"},
        component: () => import('@pages/PeriodoLetivo/PeriodoLetivoFinalizar')
    },
    {
        path: 'turma',
        name: 'TurmaList',
        meta: {title: "Turmas"},
        component: () => import('@pages/Turma/Turma')
    },
    {
        path: 'turma/create',
        props:true,
        name: 'TurmaCreate',
        meta: {title: "Cadastro de Turma"},
        component: () => import('@pages/Turma/TurmaCreate')
    },
    {
        path: 'turma/edit/:id',
        props: true,
        name: 'TurmaEdit',
        meta: {title: "Edição de Turma"},
        component: () => import('@pages/Turma/TurmaEdit')
    },
    {
        path: 'turma/disciplinas/:id',
        props: true,
        name: 'TurmaDisciplina',
        meta: {title: "Edição de Disciplinas"},
        component: () => import('@pages/Turma/TurmaDisciplina')
    },
    {
        path: 'turma/show/:id',
        props: true,
        name: 'TurmaShow',
        meta: {title: "Visualização de Turma"},
        component: () => import('@pages/Turma/TurmaShow')
    },
    {
        path: 'turma/horario-aula/:id',
        props: true,
        name: 'HorarioAula',
        meta: {title: "Horários de Aula"},
        component: () => import('@pages/Turma/HorarioAula')
    },
    {
        path: 'turma/link/:id',
        props: true,
        name: 'TurmaLink',
        meta: {title: "Links da Turma"},
        component: () => import('@pages/Turma/TurmaLink')
    },
    {
        path: 'turma/link/create/:id',
        props: true,
        name: 'TurmaLinkCreate',
        meta: {title: "Links da Turma"},
        component: () => import('@pages/Turma/TurmaLinkCreate')
    },
    {
        path: 'turno',
        name: 'TurnoList',
        meta: {title: "Turnos"},
        component: () => import('@pages/Turno/Turno')
    },
    {
        path: 'turno/create',
        name: 'TurnoCreate',
        meta: {title: "Cadastro de Turno"},
        component: () => import('@pages/Turno/TurnoCreate')
    },
    {
        path: 'turno/edit/:id',
        props: true,
        name: 'TurnoEdit',
        meta: {title: "Edição de Turno"},
        component: () => import('@pages/Turno/TurnoEdit')
    },
    {
        path: 'servico',
        name: 'ServicoList',
        meta: {title: "Serviços"},
        component: () => import('@pages/Servico/ServicoList')
    },
    {
        path: 'servico/create',
        name: 'ServicoCreate',
        meta: {title: "Cadastro de Serviço"},
        component: () => import('@pages/Servico/ServicoCreate')
    },
    {
        path: 'servico/edit/:id',
        props: true,
        name: 'ServicoEdit',
        meta: {title: "Edição de Serviço"},
        component: () => import('@pages/Servico/ServicoEdit')
    },
    {
        path: 'servico/aluno-vinculado/:id',
        props: true,
        name: 'ServicoAlunoVinculado',
        meta: {title: "Vincular Aluno"},
        component: () => import('@pages/Servico/AlunoVinculado')
    },
    {
        path: 'servico/aluno-vinculado/add/:id',
        props: true,
        name: 'AdicionarServicoAlunoVinculado',
        meta: {title: "Vincular Aluno"},
        component: () => import('@pages/Servico/AdicionarAlunoVinculado')
    },
    {
        path: 'grupo-servico',
        name: 'GrupoServicoList',
        meta: {title: "Grupo de Serviços"},
        component: () => import('@pages/GrupoServico/GrupoServicoList')
    },
    {
        path: 'grupo-servico/create',
        name: 'GrupoServicoCreate',
        meta: {title: "Cadastro de Grupo de Serviço"},
        component: () => import('@pages/GrupoServico/GrupoServicoCreate')
    },
    {
        path: 'grupo-servico/edit/:id',
        props: true,
        name: 'GrupoServicoEdit',
        meta: {title: "Edição de Grupo de Serviço"},
        component: () => import('@pages/GrupoServico/GrupoServicoEdit')
    },
    {
        path: 'gerenciamento-ano',
        name: 'GerenciamentoAnoList',
        meta: {title: "Gerenciamento de Ano Letivo"},
        component: () => import('@pages/GerenciamentoAno/GerenciamentoAno')
    },
    {
        path: 'gerenciamento-ano/create',
        name: 'GerenciamentoAnoCreate',
        meta: {title: "Cadastro de Gerenciamento de Ano Letivo"},
        component: () => import('@pages/GerenciamentoAno/GerenciamentoAnoCreate')
    },
    {
        path: 'gerenciamento-ano/edit/:id',
        props: true,
        name: 'GerenciamentoAnoEdit',
        meta: {title: "Edição de Gerenciamento de Ano Letivo"},
        component: () => import('@pages/GerenciamentoAno/GerenciamentoAnoEdit')
    },
    {
        path: 'curso',
        name: 'CursoList',
        meta: {title: "Cursos", permissions: ['curso_visualizar']},
        component: () => import('@pages/Curso/Curso')
    },
    {
        path: 'curso/create',
        props: true,
        name: 'CursoCreate',
        meta: {title: "Cadastro de Curso", permissions: ['curso_inserir']},
        component: () => import('@pages/Curso/CursoCreate')
    },
    {
        path: 'curso/edit/:id',
        props: true,
        name: 'CursoEdit',
        meta: {title: "Edição de Curso", permissions: ['curso_editar']},
        component: () => import('@pages/Curso/CursoEdit')
    },
    {
        path: 'periodo-letivo',
        name: 'PeriodoLetivoList',
        meta: {title: "Período Letivo", permissions: ['periodo_letivo_visualizar']},
        component: () => import('@pages/PeriodoLetivo/PeriodoLetivo')
    },
    {
        path: 'periodo-letivo/create',
        name: 'PeriodoLetivoCreate',
        meta: {title: "Cadastro de Período Letivo", permissions: ['periodo_letivo_inserir']},
        component: () => import('@pages/PeriodoLetivo/PeriodoLetivoCreate')
    },
    {
        path: 'periodo-letivo/edit/:id',
        props: true,
        name: 'PeriodoLetivoEdit',
        meta: {title: "Edição de Período Letivo", permissions: ['periodo_letivo_editar']},
        component: () => import('@pages/PeriodoLetivo/PeriodoLetivoEdit')
    },
    {
        path: 'periodo-letivo/financeiro/:id',
        props: true,
        name: 'PeriodoLetivoFinanceiro',
        meta: {title: "Informações Financeiras de Período Letivo", permissions: ['periodo_letivo_editar']},
        component: () => import('@pages/PeriodoLetivo/Financeiro')
    },
    {
        path: 'relatorio-personalizado',
        name: 'RelatorioPersonalizadoList',
        meta: {title: "Relatório Personalizado"},
        component: () => import('@pages/RelatorioPersonalizado/RelatorioPersonalizado')
    },
    {
        path: 'relatorio-personalizado/create',
        name: 'RelatorioPersonalizadoCreate',
        meta: {title: "Cadastro de Relatório Personalizado"},
        component: () => import('@pages/RelatorioPersonalizado/RelatorioPersonalizadoCreate')
    },
    {
        path: 'relatorio-personalizado/edit/:id',
        props: true,
        name: 'RelatorioPersonalizadoEdit',
        meta: {title: "Edição de Relatório Personalizado"},
        component: () => import('@pages/RelatorioPersonalizado/RelatorioPersonalizadoEdit')
    },

]
