export default [
    {
        path: 'resumo',
        name: 'AsaasResumo',
        meta: {title: "Asaas - Resumo",},
        component: () => import('@pages/Asaas/Resumo')
    },
    {
        path: 'cobranca',
        name: 'AsaasCobranca',
        meta: {title: "Asaas - Cobrança",},
        component: () => import('@pages/Asaas/Cobranca')
    },
    {
        path: 'extrato',
        name: 'AsaasExtrato',
        meta: {title: "Asaas - Extrato",},
        component: () => import('@pages/Asaas/MeuDinheiro/Extrato')
    },

    {
        path: 'minha-conta',
        name: 'AsaasMinhaConta',
        meta: {title: "Asaas - Minha Conta",},
        component: () => import('@pages/Asaas/Perfil/MinhaConta')
    },
]