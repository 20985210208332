export default [
    {
        path: '',
        name: 'Home',
        component: () => import('@pages/Home/Home')
    },
    {
        path: 'aluno',
        name: 'AlunoList',
        meta: { title: "Alunos", permissions: ['aluno_visualizar'] },
        component: () => import('@pages/Aluno/Aluno')
    },
    {
        path: 'aluno/show/:id',
        props: true,
        name: 'AlunoShow',
        meta: { title: "Visualizar Aluno", permissions: ['aluno_visualizar'] },
        component: () => import('@pages/Aluno/AlunoShow')
    },
    {
        path: 'aluno/historico/:aluno_id/:curso_id',
        props: true,
        name: 'HistoricoEscolar',
        meta: { title: "Histórico Escolar" , permissions: ['aluno_editar'] },
        component: () => import('@pages/Aluno/HistoricoEscolar')
    },
    {
        path: 'aluno/create',
        props: true,
        name: 'AlunoCreate',
        meta: { title: "Cadastro de Aluno", permissions: ['aluno_inserir'] },
        component: () => import('@pages/Aluno/AlunoCreate')
    },
    {
        path: 'aluno/edit/:id',
        props: true,
        name: 'AlunoEdit',
        meta: { title: "Edição de Aluno", permissions: ['aluno_editar'] },
        component: () => import('@pages/Aluno/AlunoEdit')
    },
    {
        path: 'saude/edit/:aluno_id',
        props: true,
        name: 'SaudeEdit',
        meta: { title: "Ficha de Saúde" , permissions: ['aluno_editar'] },
        component: () => import('@pages/Saude/SaudeEdit')
    },
    {
        path: 'responsavel/:id/:aluno_id',
        props: true,
        name: 'ResponsavelShow',
        meta: { title: "Visualizar Responsavel" , permissions: ['responsavel_visualizar']},
        component: () => import('@pages/Responsavel/ResponsavelShow')
    },
    {
        path: 'responsavel/create/:aluno_id',
        props: true,
        name: 'ResponsavelCreate',
        meta: { title: "Cadastro de Responsavel" , permissions: ['responsavel_inserir']},
        component: () => import('@pages/Responsavel/ResponsavelCreate')
    },
    {
        path: 'responsavel/edit/:id',
        props: true,
        name: 'ResponsavelEdit',
        meta: { title: "Edição de Responsavel", permissions: ['responsavel_editar'] },
        component: () => import('@pages/Responsavel/ResponsavelEdit')
    },
    {
        path: 'responsavel/edit/vinculo/:id/:aluno_id/:grau_parentesco_id',
        props: true,
        name: 'VinculoEdit',
        meta: { title: "Edição de Vinculo de Responsavel", permissions: ['responsavel_editar'] },
        component: () => import('@pages/Responsavel/VinculoEdit')
    },
    {
        path: 'aluno/financeiro/desconto/:id',
        props: true,
        name: 'DescontoEspecial',
        meta: { title: "Desconto Especial de Antecipação", permissions: ['aluno_inserir'] },
        component: () => import('@pages/Aluno/DescontoEspecial')
    },
    {
        path: 'aluno/financeiro/create/:id',
        props: true,
        name: 'FinanceiroCreateAluno',
        meta: { title: "Cadastro de Conta a Receber Retroativo", permissions: ['aluno_inserir'] },
        component: () => import('@pages/Aluno/FinanceiroCreate')
    },

    {
        path: 'professor',
        name: 'ProfessorList',
        meta: { title: "Professor", permissions: ['professor_visualizar'] },
        component: () => import('@pages/Professor/Professor')
    },
    {
        path: 'professor/show/:id',
        props: true,
        name: 'ProfessorShow',
        meta: { title: "Visualizar Professor", permissions: ['professor_visualizar'] },
        component: () => import('@pages/Professor/ProfessorShow')
    },
    {
        path: 'professor/dados-bancarios/edit/:id',
        props: true,
        name: 'ProfessorDadosBancariosEdit',
        meta: { title: "Editar Dados do Professor", permissions: ['professor_editar'] },
        component: () => import('@pages/Professor/DadosBancariosEdit')
    },
    {
        path: 'professor/create',
        name: 'ProfessorCreate',
        meta: { title: "Cadastro de Professor", permissions: ['professor_inserir'] },
        component: () => import('@pages/Professor/ProfessorCreate')
    },
    {
        path: 'professor/edit/:id',
        props: true,
        name: 'ProfessorEdit',
        meta: { title: "Edição de Professor", permissions: ['professor_editar'] },
        component: () => import('@pages/Professor/ProfessorEdit')
    },

    {
        path: 'cliente',
        name: 'ClienteList',
        meta: { title: "Clientes" },
        component: () => import('@pages/Cliente/Cliente')
    },
    {
        path: 'cliente/show/:id',
        props: true,
        name: 'ClienteShow',
        meta: { title: "Visualizar Cliente" },
        component: () => import('@pages/Cliente/ClienteShow')
    },
    {
        path: 'cliente/create',
        name: 'ClienteCreate',
        meta: { title: "Cadastro de Cliente" },
        component: () => import('@pages/Cliente/ClienteCreate')
    },
    {
        path: 'cliente/edit/:id',
        props: true,
        name: 'ClienteEdit',
        meta: { title: "Edição de Cliente" },
        component: () => import('@pages/Cliente/ClienteEdit')
    },
    {
        path: 'funcionario',
        name: 'FuncionarioList',
        meta: { title: "Funcionários" , permissions: ['funcionario_visualizar']},
        component: () => import('@pages/Funcionario/Funcionario')
    },
    {
        path: 'funcionario/dados-bancarios/edit/:id',
        props: true,
        name: 'FuncionarioDadosBancariosEdit',
        meta: { title: "Editar Dados do Funcionario", permissions: ['funcionario_editar'] },
        component: () => import('@pages/Funcionario/DadosBancariosEdit')
    },
    {
        path: 'funcionario/show/:id',
        props: true,
        name: 'FuncionarioShow',
        meta: { title: "Visualizar Funcionário" , permissions: ['funcionario_visualizar']},
        component: () => import('@pages/Funcionario/FuncionarioShow')
    },
    {
        path: 'funcionario/create',
        name: 'FuncionarioCreate',
        meta: { title: "Cadastro de Funcionário", permissions: ['funcionario_inserir'] },
        component: () => import('@pages/Funcionario/FuncionarioCreate')
    },
    {
        path: 'funcionario/edit/:id',
        props: true,
        name: 'FuncionarioEdit',
        meta: { title: "Edição de Funcionário" , permissions: ['funcionario_editar']},
        component: () => import('@pages/Funcionario/FuncionarioEdit')
    },
    {
        path: 'funcionario/cadastro',
        props: true,
        name: 'FuncionarioCadastro',
        meta: { title: "Meu Cadastro" , permissions: ['funcionario_editar']},
        component: () => import('@pages/Funcionario/FuncionarioCadastro')
    },
    {
        path: 'funcionario/senha/:id',
        props: true,
        name: 'FuncionarioSenhaEdit',
        meta: { title: "Alterar Senha", permissions: ['funcionario_editar'] },
        component: () => import('@pages/Funcionario/FuncionarioSenhaEdit')
    },

    {
        path: 'fornecedor',
        name: 'FornecedorList',
        meta: { title: "Fornecedores" , permissions: ['fornecedor_visualizar'] },
        component: () => import('@pages/Fornecedor/Fornecedor')
    },
    {
        path: 'fornecedor/show/:id',
        props: true,
        name: 'FornecedorShow',
        meta: { title: "Visualizar Fornecedor" , permissions: ['fornecedor_visualizar'] },
        component: () => import('@pages/Fornecedor/FornecedorShow')
    },
    {
        path: 'fornecedor/create',
        name: 'FornecedorCreate',
        meta: { title: "Cadastro de Fornecedor" , permissions: ['fornecedor_inserir'] },
        component: () => import('@pages/Fornecedor/FornecedorCreate')
    },
    {
        path: 'fornecedor/edit/:id',
        props: true,
        name: 'FornecedorEdit',
        meta: { title: "Edição de Fornecedor" , permissions: ['fornecedor_editar'] },
        component: () => import('@pages/Fornecedor/FornecedorEdit')
    },

    {
        path: 'unidade',
        name: 'UnidadeList',
        meta: { title: "Unidades" , permissions: ['unidade_visualizar'] },
        component: () => import('@pages/Unidade/Unidade')
    },
    {
        path: 'unidade/show/:id',
        props: true,
        name: 'UnidadeShow',
        meta: { title: "Visualizar Unidade" , permissions: ['unidade_visualizar'] },
        component: () => import('@pages/Unidade/UnidadeShow')
    },
    {
        path: 'unidade/create',
        name: 'UnidadeCreate',
        meta: { title: "Cadastro de Unidade" , permissions: ['unidade_inserir'] },
        component: () => import('@pages/Unidade/UnidadeCreate')
    },
    {
        path: 'unidade/edit/:id',
        props: true,
        name: 'UnidadeEdit',
        meta: { title: "Edição de Unidade" , permissions: ['unidade_editar'] },
        component: () => import('@pages/Unidade/UnidadeEdit')
    },
    {
        path: 'cargo',
        name: 'CargoList',
        meta: { title: "Cargos" , permissions: ['cargo_visualizar'] },
        component: () => import('@pages/Cargo/Cargo')
    },
    {
        path: 'cargo/create',
        props: true,
        name: 'CargoCreate',
        meta: { title: "Cadastro de Cargo"  , permissions: ['cargo_inserir']},
        component: () => import('@pages/Cargo/CargoCreate')
    },
    {
        path: 'cargo/edit/:id',
        props: true,
        name: 'CargoEdit',
        meta: { title: "Edição de Cargo" , permissions: ['cargo_editar'] },
        component: () => import('@pages/Cargo/CargoEdit')
    },
    {
        path: 'documento',
        name: 'DocumentoList',
        meta: { title: "Documentos" , permissions: ['documento_visualizar'] },
        component: () => import('@pages/Documento/Documento')
    },
    {
        path: 'documento/create',
        name: 'DocumentoCreate',
        meta: { title: "Cadastro de Documento"  , permissions: ['documento_inserir']},
        component: () => import('@pages/Documento/DocumentoCreate')
    },
    {
        path: 'documento/edit/:id',
        props: true,
        name: 'DocumentoEdit',
        meta: { title: "Edição de Documento" , permissions: ['documento_editar'] },
        component: () => import('@pages/Documento/DocumentoEdit')
    },
    {
        path: 'ocorrencia-tipo',
        name: 'OcorrenciaTipoList',
        meta: { title: "Modelo de Ocorrência"  , permissions: ['ocorrencia_visualizar']},
        component: () => import('@pages/OcorrenciaTipo/OcorrenciaTipo')
    },
    {
        path: 'ocorrencia-tipo/create',
        name: 'OcorrenciaTipoCreate',
        meta: { title: "Cadastro de Modelo de Ocorrência" , permissions: ['ocorrencia_inserir'] },
        component: () => import('@pages/OcorrenciaTipo/OcorrenciaTipoCreate')
    },
    {
        path: 'ocorrencia-tipo/edit/:id',
        props: true,
        name: 'OcorrenciaTipoEdit',
        meta: { title: "Edição de Modelo de Ocorrência" , permissions: ['ocorrencia_editar'] },
        component: () => import('@pages/OcorrenciaTipo/OcorrenciaTipoEdit')
    },
    {
        path: 'ocorrencia/create',
        props: true,
        name: 'OcorrenciaCreate',
        meta: { title: "Registro de Ocorrência" , permissions: ['aluno_inserir'] },
        component: () => import('@pages/AlunoOcorrencia/OcorrenciaCreate')
    },
    {
        path: 'ocorrencia/edit/:id',
        props: true,
        name: 'OcorrenciaEdit',
        meta: { title: "Edição de Ocorrência" , permissions: ['aluno_editar'] },
        component: () => import('@pages/AlunoOcorrencia/OcorrenciaEdit')
    },
    {
        path: 'livro',
        name: 'LivroList',
        meta: { title: "Livros" },
        component: () => import('@pages/Livro/Livro')
    },
    {
        path: 'livro/create',
        name: 'LivroCreate',
        meta: { title: "Cadastro de Livro" },
        component: () => import('@pages/Livro/LivroCreate')
    },
    {
        path: 'livro/edit/:id',
        props: true,
        name: 'LivroEdit',
        meta: { title: "Edição de Livro" },
        component: () => import('@pages/Livro/LivroEdit')
    },
    {
        path: 'listamateriais',
        name: 'ListaMaterialList',
        meta: { title: "Lista de Materiais" },
        component: () => import('@pages/ListaMaterial/ListaMaterialList')
    },
    {
        path: 'listamateriais/create',
        name: 'ListaMaterialCreate',
        meta: { title: "Cadastro de Lista de Material" },
        component: () => import('@pages/ListaMaterial/ListaMaterialCreate')
    },
    {
        path: 'listamateriais/edit/:id',
        props: true,
        name: 'ListaMaterialEdit',
        meta: { title: "Edição de Lista de Material" },
        component: () => import('@pages/ListaMaterial/ListaMaterialEdit')
    },
    {
        path: 'listamateriais/adicionar/:id',
        props: true,
        name: 'AddItemListaMaterial',
        meta: { title: "Adicionar em Lista de Material" },
        component: () => import('@pages/ListaMaterial/AddItemListaMaterial')
    },
    {
        path: 'produto',
        name: 'ProdutoList',
        meta: { title: "Produto" },
        component: () => import('@pages/Produto/ProdutoList')
    },
    {
        path: 'produto/create',
        name: 'ProdutoCreate',
        meta: { title: "Cadastrar Produto" },
        component: () => import('@pages/Produto/ProdutoCreate')
    },
    {
        path: 'produto/grade/:id',
        props: true,
        name: 'GradeProduto',
        meta: { title: "Cadastrar Grade de Produto" },
        component: () => import('@pages/Produto/GradeProduto')
    },
    {
        path: 'produto/edit/:id',
        props: true,
        name: 'ProdutoEdit',
        meta: { title: "Edição de Produto" },
        component: () => import('@pages/Produto/ProdutoEdit')
    },
    {
        path: 'produto/grupo',
        name: 'GrupoProdutoList',
        meta: { title: "Grupos de Produto" },
        component: () => import('@pages/GrupoProduto/GrupoProdutoList')
    },
    {
        path: 'produto/grupo/create',
        name: 'GrupoProdutoCreate',
        meta: { title: "Cadastrar Grupo de Produto" },
        component: () => import('@pages/GrupoProduto/GrupoProdutoCreate')
    },
    {
        path: 'produto/grupo/edit/:id',
        props: true,
        name: 'GrupoProdutoEdit',
        meta: { title: "Edição de Grupo de Produto" },
        component: () => import('@pages/GrupoProduto/GrupoProdutoEdit')
    },
    {
        path: 'produto/movimentacoes',
        name: 'ProdutoMovimento',
        meta: { title: "Movimentação de Produtos" },
        component: () => import('@pages/Produto/ProdutoMovimentoList')
    },
    {
        path: 'material',
        props:true,
        name: 'MaterialList',
        meta: { title: "Material" },
        component: () => import('@pages/Material/MaterialList')
    },
    {
        path: 'material/create',
        name: 'MaterialCreate',
        meta: { title: "Cadastrar Material" },
        component: () => import('@pages/Material/MaterialCreate')
    },
    {
        path: 'material/edit/:id',
        props: true,
        name: 'MaterialEdit',
        meta: { title: "Edição de Material" },
        component: () => import('@pages/Material/MaterialEdit')
    },
    {
        path: 'material/grupo',
        name: 'GrupoMaterialList',
        meta: { title: "Grupos de Material" },
        component: () => import('@pages/GrupoMaterial/GrupoMaterialList')
    },
    {
        path: 'material/grupo/create',
        name: 'GrupoMaterialCreate',
        meta: { title: "Cadastrar Grupo de Material" },
        component: () => import('@pages/GrupoMaterial/GrupoMaterialCreate')
    },
    {
        path: 'material/grupo/edit/:id',
        props: true,
        name: 'GrupoMaterialEdit',
        meta: { title: "Edição de Grupo de Material" },
        component: () => import('@pages/GrupoMaterial/GrupoMaterialEdit')
    },

    {
        path: 'material/movimentacoes',
        name: 'MovimentacaoMaterial',
        meta: { title: "Movimentação de Materiais" },
        component: () => import('@pages/Material/MaterialMovimentacoes')
    },
    {
        path: 'material/quantidademinima',
        name: 'QuantidadeMinima',
        meta: { title: "Itens com quantidade minima" },
        component: () => import('@pages/Almoxarifado/ItensQuantidadeMinima')
    },
    {
        path: 'tipo-dispensa',
        name: 'TipoDispensaList',
        meta: { title: "Tipo de Dispensa" },
        component: () => import('@pages/TipoDispensa/TipoDispensaList')
    },
    {
        path: 'tipo-dispensa/create',
        name: 'TipoDispensaCreate',
        meta: { title: "Cadastrar Tipo de Dispensa" },
        component: () => import('@pages/TipoDispensa/TipoDispensaCreate')
    },
    {
        path: 'tipo-dispensa/edit/:id',
        props: true,
        name: 'TipoDispensaEdit',
        meta: { title: "Edição de Tipo de Dispensa" },
        component: () => import('@pages/TipoDispensa/TipoDispensaEdit')
    },
    {
        path: 'professor/horarios/:id',
        props: true,
        name: 'HorariosProfessorShow',
        meta: { title: "Horários do professor" },
        component: () => import('@pages/Professor/Horarios')
    },
    {
        path: 'lista-livro',
        name: 'ListaLivroList',
        meta: {title: "Lista de Livros"},
        component: () => import('@pages/ListaLivro/ListaLivro')
    },
    {
        path: 'lista-livro/create',
        name: 'ListaLivroCreate',
        meta: {title: "Cadastro de Lista de Livros"},
        component: () => import('@pages/ListaLivro/ListaLivroCreate')
    },
    {
        path: 'lista-livro/edit/:id',
        props: true,
        name: 'ListaLivroEdit',
        meta: {title: "Edição de Lista de Livros"},
        component: () => import('@pages/ListaLivro/ListaLivroEdit')
    },
    {
        path: 'lista-livro/livros/:id',
        props: true,
        name: 'ListaLivroLivros',
        meta: {title: "Cadastro de Livro na Lista de Livros"},
        component: () => import('@pages/ListaLivro/Livros')
    },
    {
        path: 'lista-documento',
        name: 'ListaDocumentoList',
        meta: {title: "Lista de Documentos"},
        component: () => import('@pages/ListaDocumento/ListaDocumento')
    },
    {
        path: 'lista-documento/create',
        name: 'ListaDocumentoCreate',
        meta: {title: "Cadastro de Lista de Documentos"},
        component: () => import('@pages/ListaDocumento/ListaDocumentoCreate')
    },
    {
        path: 'lista-documento/edit/:id',
        props: true,
        name: 'ListaDocumentoEdit',
        meta: {title: "Edição de Lista de Documentos"},
        component: () => import('@pages/ListaDocumento/ListaDocumentoEdit')
    },
    {
        path: 'lista-documento/documentos/:id',
        props: true,
        name: 'ListaDocumentoDocumentos',
        meta: {title: "Cadastro de Documento na Lista de Documentos"},
        component: () => import('@pages/ListaDocumento/Documentos')
    },
]
