import asaasRoutes from './asaas'

export default [
    {
        path: 'financeiro',
        name: 'DashboardFinanceiro',
        meta: {title: "Financeiro",},
        component: () => import('@pages/Financeiro/Dashboard')
    },
    {
        path: 'boleto/:convenio_bancario_id',
        props: true,
        name: 'BoletoList',
        meta: {title: "Boletos", permissions: ['boleto_visualizar']},
        component: () => import('@pages/Boleto/Boleto')
    },
    {
        path: 'lista-remessa/:convenio_bancario_id',
        props: true,
        name: 'ListaRemessa',
        meta: {title: "Boletos", permissions: ['boleto_visualizar']},
        component: () => import('@pages/Boleto/ListaRemessa')
    },
    {
        path: 'lista-retorno/:convenio_bancario_id',
        props: true,
        name: 'ListaRetorno',
        meta: {title: "Boletos", permissions: ['boleto_visualizar']},
        component: () => import('@pages/Boleto/ListaRetorno')
    },
    {
        path: 'boleto-convenio',
        name: 'ConvenioBoletoList',
        meta: {title: "Boletos", permissions: ['boleto_visualizar']},
        component: () => import('@pages/Boleto/ConvenioList')
    },
    {
        path: 'remessa-convenio/:convenio_bancario_id',
        props: true,
        name: 'RemessaBoletoList',
        meta: {title: "Criar Remessa", permissions: ['boleto_visualizar']},
        component: () => import('@pages/Boleto/RemessaBoletoList')
    },
    {
        path: 'conta',
        name: 'ContaList',
        meta: {title: "Contas", permissions: ['conta_visualizar']},
        component: () => import('@pages/Conta/Conta')
    },
    {
        path: 'conta/create',
        name: 'ContaCreate',
        meta: {title: "Cadastro de Conta", permissions: ['conta_inserir']},
        component: () => import('@pages/Conta/ContaCreate')
    },
    {
        path: 'conta/edit/:id',
        props: true,
        name: 'ContaEdit',
        meta: {title: "Edição de Conta", permissions: ['conta_editar']},
        component: () => import('@pages/Conta/ContaEdit')
    },

    {
        path: 'convenio',
        name: 'ConvenioList',
        meta: {title: "Convênio Bancário"},
        component: () => import('@pages/Convenio/Convenio')
    },
    {
        path: 'convenio/create',
        name: 'ConvenioCreate',
        meta: {title: "Cadastro de Convênio Bancário"},
        component: () => import('@pages/Convenio/ConvenioCreate')
    },
    {
        path: 'convenio/edit/:id',
        props: true,
        name: 'ConvenioEdit',
        meta: {title: "Edição de Convênio Bancário"},
        component: () => import('@pages/Convenio/ConvenioEdit')
    },

    {
        path: 'forma-pagamento',
        name: 'FormaPagamentoList',
        meta: {title: "Forma de Pagamentos", permissions: ['forma_pagamento_visualizar']},
        component: () => import('@pages/FormaPagamento/FormaPagamento')
    },
    {
        path: 'forma-pagamento/create',
        name: 'FormaPagamentoCreate',
        meta: {title: "Cadastro de Forma de Pagamento", permissions: ['forma_pagamento_inserir']},
        component: () => import('@pages/FormaPagamento/FormaPagamentoCreate')
    },
    {
        path: 'forma-pagamento/edit/:id',
        props: true,
        name: 'FormaPagamentoEdit',
        meta: {title: "Edição de Forma de Pagamento", permissions: ['forma_pagamento_editar']},
        component: () => import('@pages/FormaPagamento/FormaPagamentoEdit')
    },

    {
        path: 'categoria',
        name: 'CategoriaList',
        meta: {title: "Categorias de Lançamentos", permissions: ['categoria_visualizar']},
        component: () => import('@pages/Categoria/Categoria')
    },
    {
        path: 'categoria/create',
        name: 'CategoriaCreate',
        meta: {title: "Cadastro de Categorias de Lançamentos", permissions: ['categoria_inserir']},
        component: () => import('@pages/Categoria/CategoriaCreate')
    },
    {
        path: 'categoria/edit/:id',
        props: true,
        name: 'CategoriaEdit',
        meta: {title: "Edição de Categorias de Lançamentos", permissions: ['categoria_editar']},
        component: () => import('@pages/Categoria/CategoriaEdit')
    },
    {
        path: 'cfgcategoria',
        props: true,
        name: 'CfgCategoria',
        meta: {title: "Configuração de Categoria", permissions: ['categoria_editar']},
        component: () => import('@pages/CfgCategoria/CfgCategoria')
    },

    {
        path: 'conta-receber',
        props: true,
        name: 'ContaReceberList',
        meta: {title: "Contas a Receber", permissions: ['conta_receber_visualizar']},
        component: () => import('@pages/ContaReceber/ContaReceber')
    },
    {
        path: 'conta-receber/create',
        name: 'ContaReceberCreate',
        meta: {title: "Cadastro de Contas a Receber", permissions: ['conta_receber_inserir']},
        component: () => import('@pages/ContaReceber/ContaReceberCreate')
    },
    {
        path: 'conta-receber/edit/:id',
        props: true,
        name: 'ContaReceberEdit',
        meta: {title: "Edição de Conta a Receber", permissions: ['conta_receber_editar']},
        component: () => import('@pages/Lancamento/LancamentoContaEdit')
    },

    {
        path: 'conta-pagar',
        props: true,
        name: 'ContaPagarList',
        meta: {title: "Contas a Pagar", permissions: ['conta_pagar_visualizar']},
        component: () => import('@pages/ContaPagar/ContaPagar')
    },
    {
        path: 'conta-pagar/create',
        name: 'ContaPagarCreate',
        meta: {title: "Cadastro de Contas a Pagar", permissions: ['conta_pagar_inserir']},
        component: () => import('@pages/ContaPagar/ContaPagarCreate')
    },
    {
        path: 'conta-pagar/edit/:id',
        props: true,
        name: 'ContaPagarEdit',
        meta: {title: "Edição de Conta a Pagar", permissions: ['conta_pagar_editar']},
        component: () => import('@pages/Lancamento/LancamentoContaEdit')
    },

    {
        path: 'lancamento',
        name: 'LancamentoList',
        meta: {title: "Lançamentos Financeiros" , permissions: ['lancamento_visualizar']},
        component: () => import('@pages/Lancamento/Lancamento')
    },
    {
        path: 'lancamento/edit/:id/:tipo',
        props: true,
        name: 'LancamentoEdit',
        meta: {title: "Edição de Lançamentos Financeiros" , permissions: ['lancamento_editar']},
        component: () => import('@pages/Lancamento/LancamentoEdit')
    },
    {
        path: 'lancamento/edit-vencimento/:id',
        props: true,
        name: 'LancamentoEditVencimento',
        meta: {title: "Edição de Lançamentos Financeiros" , permissions: ['lancamento_editar']},
        component: () => import('@pages/Lancamento/LancamentoEditVencimento')
    },
    {
        path: 'lancamento/show/:id',
        props: true,
        name: 'LancamentoShow',
        meta: {title: "Lançamentos Financeiros" , permissions: ['lancamento_visualizar']},
        component: () => import('@pages/Lancamento/LancamentoShow')
    },

    {
        path: 'caixa',
        props: true,
        name: 'Caixa',
        meta: {title: "Caixa"},
        component: () => import('@pages/Caixa/Caixa')
    },
    {
        path: 'caixa/lancamento/:tipo',
        props: true,
        name: 'CaixaCreate',
        meta: {title: "Lançamento de Caixa"},
        component: () => import('@pages/Caixa/CaixaCreate')
    },
    {
        path: 'plano-gerencial',
        name: 'PlanoGerencialList',
        meta: {title: "Plano de Conta Gerencial"},
        component: () => import('@pages/PlanoGerencial/PlanoGerencial')
    },
    {
        path: 'plano-gerencial/create',
        name: 'PlanoGerencialCreate',
        meta: {title: "Cadastro de Plano de Conta Gerencial"},
        component: () => import('@pages/PlanoGerencial/PlanoGerencialCreate')
    },
    {
        path: 'plano-gerencial/edit',
        name: 'PlanoGerencialEdit',
        meta: {title: "Alteração de Plano de Conta Gerencial"},
        component: () => import('@pages/PlanoGerencial/PlanoGerencialEdit')
    },
    {
        path: 'plano-gerencial/configuracao',
        name: 'ConfiguracaoEvento',
        meta: {title: "Configurações de Eventos Padrão"},
        component: () => import('@pages/PlanoGerencial/ConfiguracaoEvento')
    },
    {
        path: 'cobranca',
        props: true,
        name: 'ContatoCobrancaList',
        meta: {title: "Contatos de Cobrança"},
        component: () => import('@pages/ContatoCobranca/ContatoCobranca')
    },
    {
        path: 'cobranca/show/:id',
        props: true,
        name: 'ContatoCobrancaShow',
        meta: {title: "Detalhamento de Débitos"},
        component: () => import('@pages/ContatoCobranca/ContatoCobrancaShow')
    },

    {
        path: 'asaas',
        name: 'AsaasDashboard',
        meta: {title: "Asaas"},
        component: () => import('@pages/Asaas/Asaas'),
        children: [
            ...asaasRoutes,
        ]
    },
   
]
